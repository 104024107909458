jQuery(function($) {
    setTimeout(function() {
        $(".block-phone .uk-slideset-nav li a").mouseenter(function(event) {
            $(this).trigger("click");
        });
    }, 200);
    var intervalId = 0;
    function start_timer() {
        $(".uk-slideset-nav > li").each(function(index, el) {
            if ($(this).hasClass("uk-active")) {
                $(this).next("li").find("a").trigger("click");
                if (index == 3) {
                    $(".uk-slideset-nav > li[data-uk-slideset-item=0] > a").trigger("click");
                }
            }
        });
    }
    $(".block-phone .uk-dotnav").mouseenter(function(event) {
        if (intervalId > 0) {
            clearInterval(intervalId);
            intervalId = 0;
        }
        console.log("stop");
    });
    $(".block-phone .uk-dotnav").mouseleave(function(event) {
        intervalId = setInterval(start_timer, 7e3);
        console.log("start");
    });
    intervalId = setInterval(start_timer, 7e3);
    setTimeout(function() {
        (function($) {
            window.addRule = function(selector, styles, sheet) {
                styles = function(styles) {
                    if (typeof styles === "string") return styles;
                    var clone = "";
                    for (var prop in styles) {
                        if (styles.hasOwnProperty(prop)) {
                            var val = styles[prop];
                            prop = prop.replace(/([A-Z])/g, "-$1").toLowerCase();
                            clone += prop + ":" + (prop === "content" ? '"' + val + '"' : val) + "; ";
                        }
                    }
                    return clone;
                }(styles);
                sheet = sheet || document.styleSheets[document.styleSheets.length - 1];
                if (sheet.insertRule) sheet.insertRule(selector + " {" + styles + "}", sheet.cssRules.length); else if (sheet.addRule) sheet.addRule(selector, styles);
                return this;
            };
            if ($) $.fn.addRule = function(styles, sheet) {
                addRule(this.selector, styles, sheet);
                return this;
            };
        })(this.jQuery || this.Zepto);
        if ($("html").attr("lang") == "EN") {
            $(".block-phone .uk-dotnav > li:nth-of-type(1):after").addRule("content: 'Guardian/Guarded'");
            $(".block-phone .uk-dotnav > li:nth-of-type(2):after").addRule("content: 'Places'");
            $(".block-phone .uk-dotnav > li:nth-of-type(3):after").addRule("content: 'Where are you'");
            $(".block-phone .uk-dotnav > li:nth-of-type(4):after").addRule("content: 'Privacy'");
        }
        if ($("html").attr("lang") == "RU") {
            $(".block-phone .uk-dotnav > li:nth-of-type(1):after").addRule("content: 'Доверенные контакты'");
            $(".block-phone .uk-dotnav > li:nth-of-type(2):after").addRule("content: 'Места'");
            $(".block-phone .uk-dotnav > li:nth-of-type(3):after").addRule("content: 'Где ты'");
            $(".block-phone .uk-dotnav > li:nth-of-type(4):after").addRule("content: 'Привастность'");
        }
        if ($("html").attr("lang") == "UA") {
            $(".block-phone .uk-dotnav > li:nth-of-type(1):after").addRule("content: 'Довірені контакти'");
            $(".block-phone .uk-dotnav > li:nth-of-type(2):after").addRule("content: 'Місця'");
            $(".block-phone .uk-dotnav > li:nth-of-type(3):after").addRule("content: 'Де ти'");
            $(".block-phone .uk-dotnav > li:nth-of-type(4):after").addRule("content: 'Приватність'");
        }
        if ($("html").attr("lang") == "IT") {
            $(".block-phone .uk-dotnav > li:nth-of-type(1):after").addRule("content: 'Sorvegliante/Sorvegliato'");
            $(".block-phone .uk-dotnav > li:nth-of-type(2):after").addRule("content: 'Luoghi'");
            $(".block-phone .uk-dotnav > li:nth-of-type(3):after").addRule("content: 'Dove sei'");
            $(".block-phone .uk-dotnav > li:nth-of-type(4):after").addRule("content: 'Privacy'");
        }
        if ($("html").attr("lang") == "DE") {
            $(".block-phone .uk-dotnav > li:nth-of-type(1):after").addRule("content: 'Guardian/Beschützt'");
            $(".block-phone .uk-dotnav > li:nth-of-type(2):after").addRule("content: 'Orte'");
            $(".block-phone .uk-dotnav > li:nth-of-type(3):after").addRule("content: 'Wo bist Du'");
            $(".block-phone .uk-dotnav > li:nth-of-type(4):after").addRule("content: 'Privatsphäre'");
        }
    }, 500);
});

(function(core) {
    var uikit;
    if (!window.jQuery) {
        throw new Error("UIkit 2.x requires jQuery");
    } else {
        uikit = core(window.jQuery);
    }
    if (typeof define == "function" && define.amd) {
        define("uikit", function() {
            uikit.load = function(res, req, onload, config) {
                var resources = res.split(","), load = [], i, base = (config.config && config.config.uikit && config.config.uikit.base ? config.config.uikit.base : "").replace(/\/+$/g, "");
                if (!base) {
                    throw new Error("Please define base path to UIkit in the requirejs config.");
                }
                for (i = 0; i < resources.length; i += 1) {
                    var resource = resources[i].replace(/\./g, "/");
                    load.push(base + "/components/" + resource);
                }
                req(load, function() {
                    onload(uikit);
                });
            };
            return uikit;
        });
    }
})(function($) {
    "use strict";
    if (window.UIkit2) {
        return window.UIkit2;
    }
    var UI = {}, _UI = window.UIkit || undefined;
    UI.version = "2.27.5";
    UI.noConflict = function() {
        if (_UI) {
            window.UIkit = _UI;
            $.UIkit = _UI;
            $.fn.uk = _UI.fn;
        }
        return UI;
    };
    window.UIkit2 = UI;
    if (!_UI) {
        window.UIkit = UI;
    }
    UI.$ = $;
    UI.$doc = UI.$(document);
    UI.$win = UI.$(window);
    UI.$html = UI.$("html");
    UI.support = {};
    UI.support.transition = function() {
        var transitionEnd = function() {
            var element = document.body || document.documentElement, transEndEventNames = {
                WebkitTransition: "webkitTransitionEnd",
                MozTransition: "transitionend",
                OTransition: "oTransitionEnd otransitionend",
                transition: "transitionend"
            }, name;
            for (name in transEndEventNames) {
                if (element.style[name] !== undefined) return transEndEventNames[name];
            }
        }();
        return transitionEnd && {
            end: transitionEnd
        };
    }();
    UI.support.animation = function() {
        var animationEnd = function() {
            var element = document.body || document.documentElement, animEndEventNames = {
                WebkitAnimation: "webkitAnimationEnd",
                MozAnimation: "animationend",
                OAnimation: "oAnimationEnd oanimationend",
                animation: "animationend"
            }, name;
            for (name in animEndEventNames) {
                if (element.style[name] !== undefined) return animEndEventNames[name];
            }
        }();
        return animationEnd && {
            end: animationEnd
        };
    }();
    (function() {
        Date.now = Date.now || function() {
            return new Date().getTime();
        };
        var vendors = [ "webkit", "moz" ];
        for (var i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {
            var vp = vendors[i];
            window.requestAnimationFrame = window[vp + "RequestAnimationFrame"];
            window.cancelAnimationFrame = window[vp + "CancelAnimationFrame"] || window[vp + "CancelRequestAnimationFrame"];
        }
        if (/iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) || !window.requestAnimationFrame || !window.cancelAnimationFrame) {
            var lastTime = 0;
            window.requestAnimationFrame = function(callback) {
                var now = Date.now();
                var nextTime = Math.max(lastTime + 16, now);
                return setTimeout(function() {
                    callback(lastTime = nextTime);
                }, nextTime - now);
            };
            window.cancelAnimationFrame = clearTimeout;
        }
    })();
    UI.support.touch = "ontouchstart" in document || window.DocumentTouch && document instanceof window.DocumentTouch || window.navigator.msPointerEnabled && window.navigator.msMaxTouchPoints > 0 || window.navigator.pointerEnabled && window.navigator.maxTouchPoints > 0 || false;
    UI.support.mutationobserver = window.MutationObserver || window.WebKitMutationObserver || null;
    UI.Utils = {};
    UI.Utils.isFullscreen = function() {
        return document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || document.fullscreenElement || false;
    };
    UI.Utils.str2json = function(str, notevil) {
        try {
            if (notevil) {
                return JSON.parse(str.replace(/([\$\w]+)\s*:/g, function(_, $1) {
                    return '"' + $1 + '":';
                }).replace(/'([^']+)'/g, function(_, $1) {
                    return '"' + $1 + '"';
                }));
            } else {
                return new Function("", "var json = " + str + "; return JSON.parse(JSON.stringify(json));")();
            }
        } catch (e) {
            return false;
        }
    };
    UI.Utils.debounce = function(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    UI.Utils.throttle = function(func, limit) {
        var wait = false;
        return function() {
            if (!wait) {
                func.call();
                wait = true;
                setTimeout(function() {
                    wait = false;
                }, limit);
            }
        };
    };
    UI.Utils.removeCssRules = function(selectorRegEx) {
        var idx, idxs, stylesheet, _i, _j, _k, _len, _len1, _len2, _ref;
        if (!selectorRegEx) return;
        setTimeout(function() {
            try {
                _ref = document.styleSheets;
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                    stylesheet = _ref[_i];
                    idxs = [];
                    stylesheet.cssRules = stylesheet.cssRules;
                    for (idx = _j = 0, _len1 = stylesheet.cssRules.length; _j < _len1; idx = ++_j) {
                        if (stylesheet.cssRules[idx].type === CSSRule.STYLE_RULE && selectorRegEx.test(stylesheet.cssRules[idx].selectorText)) {
                            idxs.unshift(idx);
                        }
                    }
                    for (_k = 0, _len2 = idxs.length; _k < _len2; _k++) {
                        stylesheet.deleteRule(idxs[_k]);
                    }
                }
            } catch (_error) {}
        }, 0);
    };
    UI.Utils.isInView = function(element, options) {
        var $element = $(element);
        if (!$element.is(":visible")) {
            return false;
        }
        var window_left = UI.$win.scrollLeft(), window_top = UI.$win.scrollTop(), offset = $element.offset(), left = offset.left, top = offset.top;
        options = $.extend({
            topoffset: 0,
            leftoffset: 0
        }, options);
        if (top + $element.height() >= window_top && top - options.topoffset <= window_top + UI.$win.height() && left + $element.width() >= window_left && left - options.leftoffset <= window_left + UI.$win.width()) {
            return true;
        } else {
            return false;
        }
    };
    UI.Utils.checkDisplay = function(context, initanimation) {
        var elements = UI.$("[data-uk-margin], [data-uk-grid-match], [data-uk-grid-margin], [data-uk-check-display]", context || document), animated;
        if (context && !elements.length) {
            elements = $(context);
        }
        elements.trigger("display.uk.check");
        if (initanimation) {
            if (typeof initanimation != "string") {
                initanimation = '[class*="uk-animation-"]';
            }
            elements.find(initanimation).each(function() {
                var ele = UI.$(this), cls = ele.attr("class"), anim = cls.match(/uk-animation-(.+)/);
                ele.removeClass(anim[0]).width();
                ele.addClass(anim[0]);
            });
        }
        return elements;
    };
    UI.Utils.options = function(string) {
        if ($.type(string) != "string") return string;
        if (string.indexOf(":") != -1 && string.trim().substr(-1) != "}") {
            string = "{" + string + "}";
        }
        var start = string ? string.indexOf("{") : -1, options = {};
        if (start != -1) {
            try {
                options = UI.Utils.str2json(string.substr(start));
            } catch (e) {}
        }
        return options;
    };
    UI.Utils.animate = function(element, cls) {
        var d = $.Deferred();
        element = UI.$(element);
        element.css("display", "none").addClass(cls).one(UI.support.animation.end, function() {
            element.removeClass(cls);
            d.resolve();
        });
        element.css("display", "");
        return d.promise();
    };
    UI.Utils.uid = function(prefix) {
        return (prefix || "id") + new Date().getTime() + "RAND" + Math.ceil(Math.random() * 1e5);
    };
    UI.Utils.template = function(str, data) {
        var tokens = str.replace(/\n/g, "\\n").replace(/\{\{\{\s*(.+?)\s*\}\}\}/g, "{{!$1}}").split(/(\{\{\s*(.+?)\s*\}\})/g), i = 0, toc, cmd, prop, val, fn, output = [], openblocks = 0;
        while (i < tokens.length) {
            toc = tokens[i];
            if (toc.match(/\{\{\s*(.+?)\s*\}\}/)) {
                i = i + 1;
                toc = tokens[i];
                cmd = toc[0];
                prop = toc.substring(toc.match(/^(\^|\#|\!|\~|\:)/) ? 1 : 0);
                switch (cmd) {
                  case "~":
                    output.push("for(var $i=0;$i<" + prop + ".length;$i++) { var $item = " + prop + "[$i];");
                    openblocks++;
                    break;

                  case ":":
                    output.push("for(var $key in " + prop + ") { var $val = " + prop + "[$key];");
                    openblocks++;
                    break;

                  case "#":
                    output.push("if(" + prop + ") {");
                    openblocks++;
                    break;

                  case "^":
                    output.push("if(!" + prop + ") {");
                    openblocks++;
                    break;

                  case "/":
                    output.push("}");
                    openblocks--;
                    break;

                  case "!":
                    output.push("__ret.push(" + prop + ");");
                    break;

                  default:
                    output.push("__ret.push(escape(" + prop + "));");
                    break;
                }
            } else {
                output.push("__ret.push('" + toc.replace(/\'/g, "\\'") + "');");
            }
            i = i + 1;
        }
        fn = new Function("$data", [ "var __ret = [];", "try {", "with($data){", !openblocks ? output.join("") : '__ret = ["Not all blocks are closed correctly."]', "};", "}catch(e){__ret = [e.message];}", 'return __ret.join("").replace(/\\n\\n/g, "\\n");', "function escape(html) { return String(html).replace(/&/g, '&amp;').replace(/\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');}" ].join("\n"));
        return data ? fn(data) : fn;
    };
    UI.Utils.focus = function(element, extra) {
        element = $(element);
        if (!element.length) {
            return element;
        }
        var autofocus = element.find("[autofocus]:first"), tabidx;
        if (autofocus.length) {
            return autofocus.focus();
        }
        autofocus = element.find(":input" + (extra && "," + extra || "")).first();
        if (autofocus.length) {
            return autofocus.focus();
        }
        if (!element.attr("tabindex")) {
            tabidx = 1e3;
            element.attr("tabindex", tabidx);
        }
        element[0].focus();
        if (tabidx) {
            element.attr("tabindex", "");
        }
        return element;
    };
    UI.Utils.events = {};
    UI.Utils.events.click = UI.support.touch ? "tap" : "click";
    UI.fn = function(command, options) {
        var args = arguments, cmd = command.match(/^([a-z\-]+)(?:\.([a-z]+))?/i), component = cmd[1], method = cmd[2];
        if (!UI[component]) {
            $.error("UIkit component [" + component + "] does not exist.");
            return this;
        }
        return this.each(function() {
            var $this = $(this), data = $this.data(component);
            if (!data) $this.data(component, data = UI[component](this, method ? undefined : options));
            if (method) data[method].apply(data, Array.prototype.slice.call(args, 1));
        });
    };
    $.UIkit = UI;
    $.fn.uk = UI.fn;
    UI.langdirection = UI.$html.attr("dir") == "rtl" ? "right" : "left";
    UI.components = {};
    UI.component = function(name, def, override) {
        if (UI.components[name] && !override) {
            return UI.components[name];
        }
        var fn = function(element, options) {
            var $this = this;
            this.UIkit = UI;
            this.element = element ? UI.$(element) : null;
            this.options = $.extend(true, {}, this.defaults, options);
            this.plugins = {};
            if (this.element) {
                this.element.data(name, this);
            }
            this.init();
            (this.options.plugins.length ? this.options.plugins : Object.keys(fn.plugins)).forEach(function(plugin) {
                if (fn.plugins[plugin].init) {
                    fn.plugins[plugin].init($this);
                    $this.plugins[plugin] = true;
                }
            });
            this.trigger("init.uk.component", [ name, this ]);
            return this;
        };
        fn.plugins = {};
        $.extend(true, fn.prototype, {
            defaults: {
                plugins: []
            },
            boot: function() {},
            init: function() {},
            on: function(a1, a2, a3) {
                return UI.$(this.element || this).on(a1, a2, a3);
            },
            one: function(a1, a2, a3) {
                return UI.$(this.element || this).one(a1, a2, a3);
            },
            off: function(evt) {
                return UI.$(this.element || this).off(evt);
            },
            trigger: function(evt, params) {
                return UI.$(this.element || this).trigger(evt, params);
            },
            find: function(selector) {
                return UI.$(this.element ? this.element : []).find(selector);
            },
            proxy: function(obj, methods) {
                var $this = this;
                methods.split(" ").forEach(function(method) {
                    if (!$this[method]) $this[method] = function() {
                        return obj[method].apply(obj, arguments);
                    };
                });
            },
            mixin: function(obj, methods) {
                var $this = this;
                methods.split(" ").forEach(function(method) {
                    if (!$this[method]) $this[method] = obj[method].bind($this);
                });
            },
            option: function() {
                if (arguments.length == 1) {
                    return this.options[arguments[0]] || undefined;
                } else if (arguments.length == 2) {
                    this.options[arguments[0]] = arguments[1];
                }
            }
        }, def);
        this.components[name] = fn;
        this[name] = function() {
            var element, options;
            if (arguments.length) {
                switch (arguments.length) {
                  case 1:
                    if (typeof arguments[0] === "string" || arguments[0].nodeType || arguments[0] instanceof jQuery) {
                        element = $(arguments[0]);
                    } else {
                        options = arguments[0];
                    }
                    break;

                  case 2:
                    element = $(arguments[0]);
                    options = arguments[1];
                    break;
                }
            }
            if (element && element.data(name)) {
                return element.data(name);
            }
            return new UI.components[name](element, options);
        };
        if (UI.domready) {
            UI.component.boot(name);
        }
        return fn;
    };
    UI.plugin = function(component, name, def) {
        this.components[component].plugins[name] = def;
    };
    UI.component.boot = function(name) {
        if (UI.components[name].prototype && UI.components[name].prototype.boot && !UI.components[name].booted) {
            UI.components[name].prototype.boot.apply(UI, []);
            UI.components[name].booted = true;
        }
    };
    UI.component.bootComponents = function() {
        for (var component in UI.components) {
            UI.component.boot(component);
        }
    };
    UI.domObservers = [];
    UI.domready = false;
    UI.ready = function(fn) {
        UI.domObservers.push(fn);
        if (UI.domready) {
            fn(document);
        }
    };
    UI.on = function(a1, a2, a3) {
        if (a1 && a1.indexOf("ready.uk.dom") > -1 && UI.domready) {
            a2.apply(UI.$doc);
        }
        return UI.$doc.on(a1, a2, a3);
    };
    UI.one = function(a1, a2, a3) {
        if (a1 && a1.indexOf("ready.uk.dom") > -1 && UI.domready) {
            a2.apply(UI.$doc);
            return UI.$doc;
        }
        return UI.$doc.one(a1, a2, a3);
    };
    UI.trigger = function(evt, params) {
        return UI.$doc.trigger(evt, params);
    };
    UI.domObserve = function(selector, fn) {
        if (!UI.support.mutationobserver) return;
        fn = fn || function() {};
        UI.$(selector).each(function() {
            var element = this, $element = UI.$(element);
            if ($element.data("observer")) {
                return;
            }
            try {
                var observer = new UI.support.mutationobserver(UI.Utils.debounce(function(mutations) {
                    fn.apply(element, [ $element ]);
                    $element.trigger("changed.uk.dom");
                }, 50), {
                    childList: true,
                    subtree: true
                });
                observer.observe(element, {
                    childList: true,
                    subtree: true
                });
                $element.data("observer", observer);
            } catch (e) {}
        });
    };
    UI.init = function(root) {
        root = root || document;
        UI.domObservers.forEach(function(fn) {
            fn(root);
        });
    };
    UI.on("domready.uk.dom", function() {
        UI.init();
        if (UI.domready) UI.Utils.checkDisplay();
    });
    document.addEventListener("DOMContentLoaded", function() {
        var domReady = function() {
            UI.$body = UI.$("body");
            UI.trigger("beforeready.uk.dom");
            UI.component.bootComponents();
            var rafToken = requestAnimationFrame(function() {
                var memory = {
                    dir: {
                        x: 0,
                        y: 0
                    },
                    x: window.pageXOffset,
                    y: window.pageYOffset
                };
                var fn = function() {
                    var wpxo = window.pageXOffset;
                    var wpyo = window.pageYOffset;
                    if (memory.x != wpxo || memory.y != wpyo) {
                        if (wpxo != memory.x) {
                            memory.dir.x = wpxo > memory.x ? 1 : -1;
                        } else {
                            memory.dir.x = 0;
                        }
                        if (wpyo != memory.y) {
                            memory.dir.y = wpyo > memory.y ? 1 : -1;
                        } else {
                            memory.dir.y = 0;
                        }
                        memory.x = wpxo;
                        memory.y = wpyo;
                        UI.$doc.trigger("scrolling.uk.document", [ {
                            dir: {
                                x: memory.dir.x,
                                y: memory.dir.y
                            },
                            x: wpxo,
                            y: wpyo
                        } ]);
                    }
                    cancelAnimationFrame(rafToken);
                    rafToken = requestAnimationFrame(fn);
                };
                if (UI.support.touch) {
                    UI.$html.on("touchmove touchend MSPointerMove MSPointerUp pointermove pointerup", fn);
                }
                if (memory.x || memory.y) fn();
                return fn;
            }());
            UI.trigger("domready.uk.dom");
            if (UI.support.touch) {
                if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
                    UI.$win.on("load orientationchange resize", UI.Utils.debounce(function() {
                        var fn = function() {
                            $(".uk-height-viewport").css("height", window.innerHeight);
                            return fn;
                        };
                        return fn();
                    }(), 100));
                }
            }
            UI.trigger("afterready.uk.dom");
            UI.domready = true;
            if (UI.support.mutationobserver) {
                var initFn = UI.Utils.debounce(function() {
                    requestAnimationFrame(function() {
                        UI.init(document.body);
                    });
                }, 10);
                new UI.support.mutationobserver(function(mutations) {
                    var init = false;
                    mutations.every(function(mutation) {
                        if (mutation.type != "childList") return true;
                        for (var i = 0, node; i < mutation.addedNodes.length; ++i) {
                            node = mutation.addedNodes[i];
                            if (node.outerHTML && node.outerHTML.indexOf("data-uk-") !== -1) {
                                return (init = true) && false;
                            }
                        }
                        return true;
                    });
                    if (init) initFn();
                }).observe(document.body, {
                    childList: true,
                    subtree: true
                });
            }
        };
        if (document.readyState == "complete" || document.readyState == "interactive") {
            setTimeout(domReady);
        }
        return domReady;
    }());
    UI.$html.addClass(UI.support.touch ? "uk-touch" : "uk-notouch");
    if (UI.support.touch) {
        var hoverset = false, exclude, hovercls = "uk-hover", selector = ".uk-overlay, .uk-overlay-hover, .uk-overlay-toggle, .uk-animation-hover, .uk-has-hover";
        UI.$html.on("mouseenter touchstart MSPointerDown pointerdown", selector, function() {
            if (hoverset) $("." + hovercls).removeClass(hovercls);
            hoverset = $(this).addClass(hovercls);
        }).on("mouseleave touchend MSPointerUp pointerup", function(e) {
            exclude = $(e.target).parents(selector);
            if (hoverset) {
                hoverset.not(exclude).removeClass(hovercls);
            }
        });
    }
    return UI;
});

(function($) {
    if ($.fn.swipeLeft) {
        return;
    }
    var touch = {}, touchTimeout, tapTimeout, swipeTimeout, longTapTimeout, longTapDelay = 750, gesture;
    var hasTouchEvents = "ontouchstart" in window, hasPointerEvents = window.PointerEvent, hasTouch = hasTouchEvents || window.DocumentTouch && document instanceof DocumentTouch || navigator.msPointerEnabled && navigator.msMaxTouchPoints > 0 || navigator.pointerEnabled && navigator.maxTouchPoints > 0;
    function swipeDirection(x1, x2, y1, y2) {
        return Math.abs(x1 - x2) >= Math.abs(y1 - y2) ? x1 - x2 > 0 ? "Left" : "Right" : y1 - y2 > 0 ? "Up" : "Down";
    }
    function longTap() {
        longTapTimeout = null;
        if (touch.last) {
            if (touch.el !== undefined) touch.el.trigger("longTap");
            touch = {};
        }
    }
    function cancelLongTap() {
        if (longTapTimeout) clearTimeout(longTapTimeout);
        longTapTimeout = null;
    }
    function cancelAll() {
        if (touchTimeout) clearTimeout(touchTimeout);
        if (tapTimeout) clearTimeout(tapTimeout);
        if (swipeTimeout) clearTimeout(swipeTimeout);
        if (longTapTimeout) clearTimeout(longTapTimeout);
        touchTimeout = tapTimeout = swipeTimeout = longTapTimeout = null;
        touch = {};
    }
    function isPrimaryTouch(event) {
        return event.pointerType == event.MSPOINTER_TYPE_TOUCH && event.isPrimary;
    }
    $(function() {
        var now, delta, deltaX = 0, deltaY = 0, firstTouch;
        if ("MSGesture" in window) {
            gesture = new MSGesture();
            gesture.target = document.body;
        }
        $(document).on("MSGestureEnd gestureend", function(e) {
            var swipeDirectionFromVelocity = e.originalEvent.velocityX > 1 ? "Right" : e.originalEvent.velocityX < -1 ? "Left" : e.originalEvent.velocityY > 1 ? "Down" : e.originalEvent.velocityY < -1 ? "Up" : null;
            if (swipeDirectionFromVelocity && touch.el !== undefined) {
                touch.el.trigger("swipe");
                touch.el.trigger("swipe" + swipeDirectionFromVelocity);
            }
        }).on("touchstart MSPointerDown pointerdown", function(e) {
            if (e.type == "MSPointerDown" && !isPrimaryTouch(e.originalEvent)) return;
            firstTouch = e.type == "MSPointerDown" || e.type == "pointerdown" ? e : e.originalEvent.touches[0];
            now = Date.now();
            delta = now - (touch.last || now);
            touch.el = $("tagName" in firstTouch.target ? firstTouch.target : firstTouch.target.parentNode);
            if (touchTimeout) clearTimeout(touchTimeout);
            touch.x1 = firstTouch.pageX;
            touch.y1 = firstTouch.pageY;
            if (delta > 0 && delta <= 250) touch.isDoubleTap = true;
            touch.last = now;
            longTapTimeout = setTimeout(longTap, longTapDelay);
            if (e.originalEvent && e.originalEvent.pointerId && gesture && (e.type == "MSPointerDown" || e.type == "pointerdown" || e.type == "touchstart")) {
                gesture.addPointer(e.originalEvent.pointerId);
            }
        }).on("touchmove MSPointerMove pointermove", function(e) {
            if (e.type == "MSPointerMove" && !isPrimaryTouch(e.originalEvent)) return;
            firstTouch = e.type == "MSPointerMove" || e.type == "pointermove" ? e : e.originalEvent.touches[0];
            cancelLongTap();
            touch.x2 = firstTouch.pageX;
            touch.y2 = firstTouch.pageY;
            deltaX += Math.abs(touch.x1 - touch.x2);
            deltaY += Math.abs(touch.y1 - touch.y2);
        }).on("touchend MSPointerUp pointerup", function(e) {
            if (e.type == "MSPointerUp" && !isPrimaryTouch(e.originalEvent)) return;
            cancelLongTap();
            if (touch.x2 && Math.abs(touch.x1 - touch.x2) > 30 || touch.y2 && Math.abs(touch.y1 - touch.y2) > 30) {
                swipeTimeout = setTimeout(function() {
                    if (touch.el !== undefined) {
                        touch.el.trigger("swipe");
                        touch.el.trigger("swipe" + swipeDirection(touch.x1, touch.x2, touch.y1, touch.y2));
                    }
                    touch = {};
                }, 0);
            } else if ("last" in touch) {
                if (isNaN(deltaX) || deltaX < 30 && deltaY < 30) {
                    tapTimeout = setTimeout(function() {
                        var event = $.Event("tap");
                        event.cancelTouch = cancelAll;
                        if (touch.el !== undefined) touch.el.trigger(event);
                        if (touch.isDoubleTap) {
                            if (touch.el !== undefined) touch.el.trigger("doubleTap");
                            touch = {};
                        } else {
                            touchTimeout = setTimeout(function() {
                                touchTimeout = null;
                                if (touch.el !== undefined) touch.el.trigger("singleTap");
                                touch = {};
                            }, 250);
                        }
                    }, 0);
                } else {
                    touch = {};
                }
                deltaX = deltaY = 0;
            }
        }).on("touchcancel MSPointerCancel pointercancel", function(e) {
            if (e.type == "touchcancel" && hasTouchEvents && hasTouch || !hasTouchEvents && e.type == "pointercancel" && hasPointerEvents) {
                cancelAll();
            }
        });
        $(window).on("scroll", cancelAll);
    });
    [ "swipe", "swipeLeft", "swipeRight", "swipeUp", "swipeDown", "doubleTap", "tap", "singleTap", "longTap" ].forEach(function(eventName) {
        $.fn[eventName] = function(callback) {
            return $(this).on(eventName, callback);
        };
    });
})(jQuery);

(function(UI) {
    "use strict";
    var stacks = [];
    UI.component("stackMargin", {
        defaults: {
            cls: "uk-margin-small-top",
            rowfirst: false,
            observe: false
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-margin]", context).each(function() {
                    var ele = UI.$(this);
                    if (!ele.data("stackMargin")) {
                        UI.stackMargin(ele, UI.Utils.options(ele.attr("data-uk-margin")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            UI.$win.on("resize orientationchange", function() {
                var fn = function() {
                    $this.process();
                };
                UI.$(function() {
                    fn();
                    UI.$win.on("load", fn);
                });
                return UI.Utils.debounce(fn, 20);
            }());
            this.on("display.uk.check", function(e) {
                if (this.element.is(":visible")) this.process();
            }.bind(this));
            if (this.options.observe) {
                UI.domObserve(this.element, function(e) {
                    if ($this.element.is(":visible")) $this.process();
                });
            }
            stacks.push(this);
        },
        process: function() {
            var $this = this, columns = this.element.children();
            UI.Utils.stackMargin(columns, this.options);
            if (!this.options.rowfirst || !columns.length) {
                return this;
            }
            var group = {}, minleft = false;
            columns.removeClass(this.options.rowfirst).each(function(offset, $ele) {
                $ele = UI.$(this);
                if (this.style.display != "none") {
                    offset = $ele.offset().left;
                    ((group[offset] = group[offset] || []) && group[offset]).push(this);
                    minleft = minleft === false ? offset : Math.min(minleft, offset);
                }
            });
            UI.$(group[minleft]).addClass(this.options.rowfirst);
            return this;
        }
    });
    (function() {
        var elements = [], check = function(ele) {
            if (!ele.is(":visible")) return;
            var width = ele.parent().width(), iwidth = ele.data("width"), ratio = width / iwidth, height = Math.floor(ratio * ele.data("height"));
            ele.css({
                height: width < iwidth ? height : ele.data("height")
            });
        };
        UI.component("responsiveElement", {
            defaults: {},
            boot: function() {
                UI.ready(function(context) {
                    UI.$("iframe.uk-responsive-width, [data-uk-responsive]", context).each(function() {
                        var ele = UI.$(this), obj;
                        if (!ele.data("responsiveElement")) {
                            obj = UI.responsiveElement(ele, {});
                        }
                    });
                });
            },
            init: function() {
                var ele = this.element;
                if (ele.attr("width") && ele.attr("height")) {
                    ele.data({
                        width: ele.attr("width"),
                        height: ele.attr("height")
                    }).on("display.uk.check", function() {
                        check(ele);
                    });
                    check(ele);
                    elements.push(ele);
                }
            }
        });
        UI.$win.on("resize load", UI.Utils.debounce(function() {
            elements.forEach(function(ele) {
                check(ele);
            });
        }, 15));
    })();
    UI.Utils.stackMargin = function(elements, options) {
        options = UI.$.extend({
            cls: "uk-margin-small-top"
        }, options);
        elements = UI.$(elements).removeClass(options.cls);
        var min = false;
        elements.each(function(offset, height, pos, $ele) {
            $ele = UI.$(this);
            if ($ele.css("display") != "none") {
                offset = $ele.offset();
                height = $ele.outerHeight();
                pos = offset.top + height;
                $ele.data({
                    ukMarginPos: pos,
                    ukMarginTop: offset.top
                });
                if (min === false || offset.top < min.top) {
                    min = {
                        top: offset.top,
                        left: offset.left,
                        pos: pos
                    };
                }
            }
        }).each(function($ele) {
            $ele = UI.$(this);
            if ($ele.css("display") != "none" && $ele.data("ukMarginTop") > min.top && $ele.data("ukMarginPos") > min.pos) {
                $ele.addClass(options.cls);
            }
        });
    };
    UI.Utils.matchHeights = function(elements, options) {
        elements = UI.$(elements).css("min-height", "");
        options = UI.$.extend({
            row: true
        }, options);
        var matchHeights = function(group) {
            if (group.length < 2) return;
            var max = 0;
            group.each(function() {
                max = Math.max(max, UI.$(this).outerHeight());
            }).each(function() {
                var element = UI.$(this), height = max - (element.css("box-sizing") == "border-box" ? 0 : element.outerHeight() - element.height());
                element.css("min-height", height + "px");
            });
        };
        if (options.row) {
            elements.first().width();
            setTimeout(function() {
                var lastoffset = false, group = [];
                elements.each(function() {
                    var ele = UI.$(this), offset = ele.offset().top;
                    if (offset != lastoffset && group.length) {
                        matchHeights(UI.$(group));
                        group = [];
                        offset = ele.offset().top;
                    }
                    group.push(ele);
                    lastoffset = offset;
                });
                if (group.length) {
                    matchHeights(UI.$(group));
                }
            }, 0);
        } else {
            matchHeights(elements);
        }
    };
    (function(cacheSvgs) {
        UI.Utils.inlineSvg = function(selector, root) {
            var images = UI.$(selector || 'img[src$=".svg"]', root || document).each(function() {
                var img = UI.$(this), src = img.attr("src");
                if (!cacheSvgs[src]) {
                    var d = UI.$.Deferred();
                    UI.$.get(src, {
                        nc: Math.random()
                    }, function(data) {
                        d.resolve(UI.$(data).find("svg"));
                    });
                    cacheSvgs[src] = d.promise();
                }
                cacheSvgs[src].then(function(svg) {
                    var $svg = UI.$(svg).clone();
                    if (img.attr("id")) $svg.attr("id", img.attr("id"));
                    if (img.attr("class")) $svg.attr("class", img.attr("class"));
                    if (img.attr("style")) $svg.attr("style", img.attr("style"));
                    if (img.attr("width")) {
                        $svg.attr("width", img.attr("width"));
                        if (!img.attr("height")) $svg.removeAttr("height");
                    }
                    if (img.attr("height")) {
                        $svg.attr("height", img.attr("height"));
                        if (!img.attr("width")) $svg.removeAttr("width");
                    }
                    img.replaceWith($svg);
                });
            });
        };
        UI.ready(function(context) {
            UI.Utils.inlineSvg("[data-uk-svg]", context);
        });
    })({});
    UI.Utils.getCssVar = function(name) {
        var val, doc = document.documentElement, element = doc.appendChild(document.createElement("div"));
        element.classList.add("var-" + name);
        try {
            val = JSON.parse(val = getComputedStyle(element, ":before").content.replace(/^["'](.*)["']$/, "$1"));
        } catch (e) {
            val = undefined;
        }
        doc.removeChild(element);
        return val;
    };
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("smoothScroll", {
        boot: function() {
            UI.$html.on("click.smooth-scroll.uikit", "[data-uk-smooth-scroll]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("smoothScroll")) {
                    var obj = UI.smoothScroll(ele, UI.Utils.options(ele.attr("data-uk-smooth-scroll")));
                    ele.trigger("click");
                }
                return false;
            });
        },
        init: function() {
            var $this = this;
            this.on("click", function(e) {
                e.preventDefault();
                scrollToElement(UI.$(this.hash).length ? UI.$(this.hash) : UI.$("body"), $this.options);
            });
        }
    });
    function scrollToElement(ele, options) {
        options = UI.$.extend({
            duration: 1e3,
            transition: "easeOutExpo",
            offset: 0,
            complete: function() {}
        }, options);
        var target = ele.offset().top - options.offset, docheight = UI.$doc.height(), winheight = window.innerHeight;
        if (target + winheight > docheight) {
            target = docheight - winheight;
        }
        UI.$("html,body").stop().animate({
            scrollTop: target
        }, options.duration, options.transition).promise().done(options.complete);
    }
    UI.Utils.scrollToElement = scrollToElement;
    if (!UI.$.easing.easeOutExpo) {
        UI.$.easing.easeOutExpo = function(x, t, b, c, d) {
            return t == d ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
        };
    }
})(UIkit2);

(function(UI) {
    "use strict";
    var $win = UI.$win, $doc = UI.$doc, scrollspies = [], checkScrollSpy = function() {
        for (var i = 0; i < scrollspies.length; i++) {
            window.requestAnimationFrame.apply(window, [ scrollspies[i].check ]);
        }
    };
    UI.component("scrollspy", {
        defaults: {
            target: false,
            cls: "uk-scrollspy-inview",
            initcls: "uk-scrollspy-init-inview",
            topoffset: 0,
            leftoffset: 0,
            repeat: false,
            delay: 0
        },
        boot: function() {
            $doc.on("scrolling.uk.document", checkScrollSpy);
            $win.on("load resize orientationchange", UI.Utils.debounce(checkScrollSpy, 50));
            UI.ready(function(context) {
                UI.$("[data-uk-scrollspy]", context).each(function() {
                    var element = UI.$(this);
                    if (!element.data("scrollspy")) {
                        var obj = UI.scrollspy(element, UI.Utils.options(element.attr("data-uk-scrollspy")));
                    }
                });
            });
        },
        init: function() {
            var $this = this, inviewstate, initinview, togglecls = this.options.cls.split(/,/), fn = function() {
                var elements = $this.options.target ? $this.element.find($this.options.target) : $this.element, delayIdx = elements.length === 1 ? 1 : 0, toggleclsIdx = 0;
                elements.each(function(idx) {
                    var element = UI.$(this), inviewstate = element.data("inviewstate"), inview = UI.Utils.isInView(element, $this.options), toggle = element.attr("data-uk-scrollspy-cls") || togglecls[toggleclsIdx].trim();
                    if (inview && !inviewstate && !element.data("scrollspy-idle")) {
                        if (!initinview) {
                            element.addClass($this.options.initcls);
                            $this.offset = element.offset();
                            initinview = true;
                            element.trigger("init.uk.scrollspy");
                        }
                        element.data("scrollspy-idle", setTimeout(function() {
                            element.addClass("uk-scrollspy-inview").toggleClass(toggle).width();
                            element.trigger("inview.uk.scrollspy");
                            element.data("scrollspy-idle", false);
                            element.data("inviewstate", true);
                        }, $this.options.delay * delayIdx));
                        delayIdx++;
                    }
                    if (!inview && inviewstate && $this.options.repeat) {
                        if (element.data("scrollspy-idle")) {
                            clearTimeout(element.data("scrollspy-idle"));
                            element.data("scrollspy-idle", false);
                        }
                        element.removeClass("uk-scrollspy-inview").toggleClass(toggle);
                        element.data("inviewstate", false);
                        element.trigger("outview.uk.scrollspy");
                    }
                    toggleclsIdx = togglecls[toggleclsIdx + 1] ? toggleclsIdx + 1 : 0;
                });
            };
            fn();
            this.check = fn;
            scrollspies.push(this);
        }
    });
    var scrollspynavs = [], checkScrollSpyNavs = function() {
        for (var i = 0; i < scrollspynavs.length; i++) {
            window.requestAnimationFrame.apply(window, [ scrollspynavs[i].check ]);
        }
    };
    UI.component("scrollspynav", {
        defaults: {
            cls: "uk-active",
            closest: false,
            topoffset: 0,
            leftoffset: 0,
            smoothscroll: false
        },
        boot: function() {
            $doc.on("scrolling.uk.document", checkScrollSpyNavs);
            $win.on("resize orientationchange", UI.Utils.debounce(checkScrollSpyNavs, 50));
            UI.ready(function(context) {
                UI.$("[data-uk-scrollspy-nav]", context).each(function() {
                    var element = UI.$(this);
                    if (!element.data("scrollspynav")) {
                        var obj = UI.scrollspynav(element, UI.Utils.options(element.attr("data-uk-scrollspy-nav")));
                    }
                });
            });
        },
        init: function() {
            var ids = [], links = this.find("a[href^='#']").each(function() {
                if (this.getAttribute("href").trim() !== "#") ids.push(this.getAttribute("href"));
            }), targets = UI.$(ids.join(",")), clsActive = this.options.cls, clsClosest = this.options.closest || this.options.closest;
            var $this = this, inviews, fn = function() {
                inviews = [];
                for (var i = 0; i < targets.length; i++) {
                    if (UI.Utils.isInView(targets.eq(i), $this.options)) {
                        inviews.push(targets.eq(i));
                    }
                }
                if (inviews.length) {
                    var navitems, scrollTop = $win.scrollTop(), target = function() {
                        for (var i = 0; i < inviews.length; i++) {
                            if (inviews[i].offset().top - $this.options.topoffset >= scrollTop) {
                                return inviews[i];
                            }
                        }
                    }();
                    if (!target) return;
                    if ($this.options.closest) {
                        links.blur().closest(clsClosest).removeClass(clsActive);
                        navitems = links.filter("a[href='#" + target.attr("id") + "']").closest(clsClosest).addClass(clsActive);
                    } else {
                        navitems = links.removeClass(clsActive).filter("a[href='#" + target.attr("id") + "']").addClass(clsActive);
                    }
                    $this.element.trigger("inview.uk.scrollspynav", [ target, navitems ]);
                }
            };
            if (this.options.smoothscroll && UI.smoothScroll) {
                links.each(function() {
                    UI.smoothScroll(this, $this.options.smoothscroll);
                });
            }
            fn();
            this.element.data("scrollspynav", this);
            this.check = fn;
            scrollspynavs.push(this);
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    var toggles = [];
    UI.component("toggle", {
        defaults: {
            target: false,
            cls: "uk-hidden",
            animation: false,
            duration: 200
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-toggle]", context).each(function() {
                    var ele = UI.$(this);
                    if (!ele.data("toggle")) {
                        var obj = UI.toggle(ele, UI.Utils.options(ele.attr("data-uk-toggle")));
                    }
                });
                setTimeout(function() {
                    toggles.forEach(function(toggle) {
                        toggle.getToggles();
                    });
                }, 0);
            });
        },
        init: function() {
            var $this = this;
            this.aria = this.options.cls.indexOf("uk-hidden") !== -1;
            this.on("click", function(e) {
                if ($this.element.is('a[href="#"]')) {
                    e.preventDefault();
                }
                $this.toggle();
            });
            toggles.push(this);
        },
        toggle: function() {
            this.getToggles();
            if (!this.totoggle.length) return;
            if (this.options.animation && UI.support.animation) {
                var $this = this, animations = this.options.animation.split(",");
                if (animations.length == 1) {
                    animations[1] = animations[0];
                }
                animations[0] = animations[0].trim();
                animations[1] = animations[1].trim();
                this.totoggle.css("animation-duration", this.options.duration + "ms");
                this.totoggle.each(function() {
                    var ele = UI.$(this);
                    if (ele.hasClass($this.options.cls)) {
                        ele.toggleClass($this.options.cls);
                        UI.Utils.animate(ele, animations[0]).then(function() {
                            ele.css("animation-duration", "");
                            UI.Utils.checkDisplay(ele);
                        });
                    } else {
                        UI.Utils.animate(this, animations[1] + " uk-animation-reverse").then(function() {
                            ele.toggleClass($this.options.cls).css("animation-duration", "");
                            UI.Utils.checkDisplay(ele);
                        });
                    }
                });
            } else {
                this.totoggle.toggleClass(this.options.cls);
                UI.Utils.checkDisplay(this.totoggle);
            }
            this.updateAria();
        },
        getToggles: function() {
            this.totoggle = this.options.target ? UI.$(this.options.target) : [];
            this.updateAria();
        },
        updateAria: function() {
            if (this.aria && this.totoggle.length) {
                this.totoggle.not("[aria-hidden]").each(function() {
                    UI.$(this).attr("aria-hidden", UI.$(this).hasClass("uk-hidden"));
                });
            }
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("alert", {
        defaults: {
            fade: true,
            duration: 200,
            trigger: ".uk-alert-close"
        },
        boot: function() {
            UI.$html.on("click.alert.uikit", "[data-uk-alert]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("alert")) {
                    var alert = UI.alert(ele, UI.Utils.options(ele.attr("data-uk-alert")));
                    if (UI.$(e.target).is(alert.options.trigger)) {
                        e.preventDefault();
                        alert.close();
                    }
                }
            });
        },
        init: function() {
            var $this = this;
            this.on("click", this.options.trigger, function(e) {
                e.preventDefault();
                $this.close();
            });
        },
        close: function() {
            var element = this.trigger("close.uk.alert"), removeElement = function() {
                this.trigger("closed.uk.alert").remove();
            }.bind(this);
            if (this.options.fade) {
                element.css("overflow", "hidden").css("max-height", element.height()).animate({
                    height: 0,
                    opacity: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0
                }, this.options.duration, removeElement);
            } else {
                removeElement();
            }
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("buttonRadio", {
        defaults: {
            activeClass: "uk-active",
            target: ".uk-button"
        },
        boot: function() {
            UI.$html.on("click.buttonradio.uikit", "[data-uk-button-radio]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("buttonRadio")) {
                    var obj = UI.buttonRadio(ele, UI.Utils.options(ele.attr("data-uk-button-radio"))), target = UI.$(e.target);
                    if (target.is(obj.options.target)) {
                        target.trigger("click");
                    }
                }
            });
        },
        init: function() {
            var $this = this;
            this.find($this.options.target).attr("aria-checked", "false").filter("." + $this.options.activeClass).attr("aria-checked", "true");
            this.on("click", this.options.target, function(e) {
                var ele = UI.$(this);
                if (ele.is('a[href="#"]')) e.preventDefault();
                $this.find($this.options.target).not(ele).removeClass($this.options.activeClass).blur();
                ele.addClass($this.options.activeClass);
                $this.find($this.options.target).not(ele).attr("aria-checked", "false");
                ele.attr("aria-checked", "true");
                $this.trigger("change.uk.button", [ ele ]);
            });
        },
        getSelected: function() {
            return this.find("." + this.options.activeClass);
        }
    });
    UI.component("buttonCheckbox", {
        defaults: {
            activeClass: "uk-active",
            target: ".uk-button"
        },
        boot: function() {
            UI.$html.on("click.buttoncheckbox.uikit", "[data-uk-button-checkbox]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("buttonCheckbox")) {
                    var obj = UI.buttonCheckbox(ele, UI.Utils.options(ele.attr("data-uk-button-checkbox"))), target = UI.$(e.target);
                    if (target.is(obj.options.target)) {
                        target.trigger("click");
                    }
                }
            });
        },
        init: function() {
            var $this = this;
            this.find($this.options.target).attr("aria-checked", "false").filter("." + $this.options.activeClass).attr("aria-checked", "true");
            this.on("click", this.options.target, function(e) {
                var ele = UI.$(this);
                if (ele.is('a[href="#"]')) e.preventDefault();
                ele.toggleClass($this.options.activeClass).blur();
                ele.attr("aria-checked", ele.hasClass($this.options.activeClass));
                $this.trigger("change.uk.button", [ ele ]);
            });
        },
        getSelected: function() {
            return this.find("." + this.options.activeClass);
        }
    });
    UI.component("button", {
        defaults: {},
        boot: function() {
            UI.$html.on("click.button.uikit", "[data-uk-button]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("button")) {
                    var obj = UI.button(ele, UI.Utils.options(ele.attr("data-uk-button")));
                    ele.trigger("click");
                }
            });
        },
        init: function() {
            var $this = this;
            this.element.attr("aria-pressed", this.element.hasClass("uk-active"));
            this.on("click", function(e) {
                if ($this.element.is('a[href="#"]')) e.preventDefault();
                $this.toggle();
                $this.trigger("change.uk.button", [ $this.element.blur().hasClass("uk-active") ]);
            });
        },
        toggle: function() {
            this.element.toggleClass("uk-active");
            this.element.attr("aria-pressed", this.element.hasClass("uk-active"));
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    var active = false, hoverIdle, flips = {
        x: {
            "bottom-left": "bottom-right",
            "bottom-right": "bottom-left",
            "bottom-center": "bottom-center",
            "top-left": "top-right",
            "top-right": "top-left",
            "top-center": "top-center",
            "left-top": "right-top",
            "left-bottom": "right-bottom",
            "left-center": "right-center",
            "right-top": "left-top",
            "right-bottom": "left-bottom",
            "right-center": "left-center"
        },
        y: {
            "bottom-left": "top-left",
            "bottom-right": "top-right",
            "bottom-center": "top-center",
            "top-left": "bottom-left",
            "top-right": "bottom-right",
            "top-center": "bottom-center",
            "left-top": "left-bottom",
            "left-bottom": "left-top",
            "left-center": "left-center",
            "right-top": "right-bottom",
            "right-bottom": "right-top",
            "right-center": "right-center"
        },
        xy: {
            "bottom-left": "top-right",
            "bottom-right": "top-left",
            "bottom-center": "top-center",
            "top-left": "bottom-right",
            "top-right": "bottom-left",
            "top-center": "bottom-center",
            "left-top": "right-bottom",
            "left-bottom": "right-top",
            "left-center": "right-center",
            "right-top": "left-bottom",
            "right-bottom": "left-top",
            "right-center": "left-center"
        }
    };
    UI.component("dropdown", {
        defaults: {
            mode: "hover",
            pos: "bottom-left",
            offset: 0,
            remaintime: 800,
            justify: false,
            boundary: UI.$win,
            delay: 0,
            dropdownSelector: ".uk-dropdown,.uk-dropdown-blank",
            hoverDelayIdle: 250,
            preventflip: false
        },
        remainIdle: false,
        boot: function() {
            var triggerevent = UI.support.touch ? "click" : "mouseenter";
            UI.$html.on(triggerevent + ".dropdown.uikit focus pointerdown", "[data-uk-dropdown]", function(e) {
                var ele = UI.$(this);
                if (!ele.data("dropdown")) {
                    var dropdown = UI.dropdown(ele, UI.Utils.options(ele.attr("data-uk-dropdown")));
                    if (e.type == "click" || e.type == "mouseenter" && dropdown.options.mode == "hover") {
                        dropdown.element.trigger(triggerevent);
                    }
                    if (dropdown.dropdown.length) {
                        e.preventDefault();
                    }
                }
            });
        },
        init: function() {
            var $this = this;
            this.dropdown = this.find(this.options.dropdownSelector);
            this.offsetParent = this.dropdown.parents().filter(function() {
                return UI.$.inArray(UI.$(this).css("position"), [ "relative", "fixed", "absolute" ]) !== -1;
            }).slice(0, 1);
            if (!this.offsetParent.length) {
                this.offsetParent = this.element;
            }
            this.centered = this.dropdown.hasClass("uk-dropdown-center");
            this.justified = this.options.justify ? UI.$(this.options.justify) : false;
            this.boundary = UI.$(this.options.boundary);
            if (!this.boundary.length) {
                this.boundary = UI.$win;
            }
            if (this.dropdown.hasClass("uk-dropdown-up")) {
                this.options.pos = "top-left";
            }
            if (this.dropdown.hasClass("uk-dropdown-flip")) {
                this.options.pos = this.options.pos.replace("left", "right");
            }
            if (this.dropdown.hasClass("uk-dropdown-center")) {
                this.options.pos = this.options.pos.replace(/(left|right)/, "center");
            }
            this.element.attr("aria-haspopup", "true");
            this.element.attr("aria-expanded", this.element.hasClass("uk-open"));
            this.dropdown.attr("aria-hidden", "true");
            if (this.options.mode == "click" || UI.support.touch) {
                this.on("click.uk.dropdown", function(e) {
                    var $target = UI.$(e.target);
                    if (!$target.parents($this.options.dropdownSelector).length) {
                        if ($target.is("a[href='#']") || $target.parent().is("a[href='#']") || $this.dropdown.length && !$this.dropdown.is(":visible")) {
                            e.preventDefault();
                        }
                        $target.blur();
                    }
                    if (!$this.element.hasClass("uk-open")) {
                        $this.show();
                    } else {
                        if (!$this.dropdown.find(e.target).length || $target.is(".uk-dropdown-close") || $target.parents(".uk-dropdown-close").length) {
                            $this.hide();
                        }
                    }
                });
            } else {
                this.on("mouseenter", function(e) {
                    $this.trigger("pointerenter.uk.dropdown", [ $this ]);
                    if ($this.remainIdle) {
                        clearTimeout($this.remainIdle);
                    }
                    if (hoverIdle) {
                        clearTimeout(hoverIdle);
                    }
                    if (active && active == $this) {
                        return;
                    }
                    if (active && active != $this) {
                        hoverIdle = setTimeout(function() {
                            hoverIdle = setTimeout($this.show.bind($this), $this.options.delay);
                        }, $this.options.hoverDelayIdle);
                    } else {
                        hoverIdle = setTimeout($this.show.bind($this), $this.options.delay);
                    }
                }).on("mouseleave", function() {
                    if (hoverIdle) {
                        clearTimeout(hoverIdle);
                    }
                    $this.remainIdle = setTimeout(function() {
                        if (active && active == $this) $this.hide();
                    }, $this.options.remaintime);
                    $this.trigger("pointerleave.uk.dropdown", [ $this ]);
                }).on("click", function(e) {
                    var $target = UI.$(e.target);
                    if ($this.remainIdle) {
                        clearTimeout($this.remainIdle);
                    }
                    if (active && active == $this) {
                        if (!$this.dropdown.find(e.target).length || $target.is(".uk-dropdown-close") || $target.parents(".uk-dropdown-close").length) {
                            $this.hide();
                        }
                        return;
                    }
                    if ($target.is("a[href='#']") || $target.parent().is("a[href='#']")) {
                        e.preventDefault();
                    }
                    $this.show();
                });
            }
        },
        show: function() {
            UI.$html.off("click.outer.dropdown");
            if (active && active != this) {
                active.hide(true);
            }
            if (hoverIdle) {
                clearTimeout(hoverIdle);
            }
            this.trigger("beforeshow.uk.dropdown", [ this ]);
            this.checkDimensions();
            this.element.addClass("uk-open");
            this.element.attr("aria-expanded", "true");
            this.dropdown.attr("aria-hidden", "false");
            this.trigger("show.uk.dropdown", [ this ]);
            UI.Utils.checkDisplay(this.dropdown, true);
            UI.Utils.focus(this.dropdown);
            active = this;
            this.registerOuterClick();
        },
        hide: function(force) {
            this.trigger("beforehide.uk.dropdown", [ this, force ]);
            this.element.removeClass("uk-open");
            if (this.remainIdle) {
                clearTimeout(this.remainIdle);
            }
            this.remainIdle = false;
            this.element.attr("aria-expanded", "false");
            this.dropdown.attr("aria-hidden", "true");
            this.trigger("hide.uk.dropdown", [ this, force ]);
            if (active == this) active = false;
        },
        registerOuterClick: function() {
            var $this = this;
            UI.$html.off("click.outer.dropdown");
            setTimeout(function() {
                UI.$html.on("click.outer.dropdown", function(e) {
                    if (hoverIdle) {
                        clearTimeout(hoverIdle);
                    }
                    var $target = UI.$(e.target);
                    if (active == $this && !$this.element.find(e.target).length) {
                        $this.hide(true);
                        UI.$html.off("click.outer.dropdown");
                    }
                });
            }, 10);
        },
        checkDimensions: function() {
            if (!this.dropdown.length) return;
            this.dropdown.removeClass("uk-dropdown-top uk-dropdown-bottom uk-dropdown-left uk-dropdown-right uk-dropdown-stack uk-dropdown-autoflip").css({
                topLeft: "",
                left: "",
                marginLeft: "",
                marginRight: ""
            });
            if (this.justified && this.justified.length) {
                this.dropdown.css("min-width", "");
            }
            var $this = this, pos = UI.$.extend({}, this.offsetParent.offset(), {
                width: this.offsetParent[0].offsetWidth,
                height: this.offsetParent[0].offsetHeight
            }), posoffset = this.options.offset, dropdown = this.dropdown, offset = dropdown.show().offset() || {
                left: 0,
                top: 0
            }, width = dropdown.outerWidth(), height = dropdown.outerHeight(), boundarywidth = this.boundary.width(), boundaryoffset = this.boundary[0] !== window && this.boundary.offset() ? this.boundary.offset() : {
                top: 0,
                left: 0
            }, dpos = this.options.pos;
            var variants = {
                "bottom-left": {
                    top: 0 + pos.height + posoffset,
                    left: 0
                },
                "bottom-right": {
                    top: 0 + pos.height + posoffset,
                    left: 0 + pos.width - width
                },
                "bottom-center": {
                    top: 0 + pos.height + posoffset,
                    left: 0 + pos.width / 2 - width / 2
                },
                "top-left": {
                    top: 0 - height - posoffset,
                    left: 0
                },
                "top-right": {
                    top: 0 - height - posoffset,
                    left: 0 + pos.width - width
                },
                "top-center": {
                    top: 0 - height - posoffset,
                    left: 0 + pos.width / 2 - width / 2
                },
                "left-top": {
                    top: 0,
                    left: 0 - width - posoffset
                },
                "left-bottom": {
                    top: 0 + pos.height - height,
                    left: 0 - width - posoffset
                },
                "left-center": {
                    top: 0 + pos.height / 2 - height / 2,
                    left: 0 - width - posoffset
                },
                "right-top": {
                    top: 0,
                    left: 0 + pos.width + posoffset
                },
                "right-bottom": {
                    top: 0 + pos.height - height,
                    left: 0 + pos.width + posoffset
                },
                "right-center": {
                    top: 0 + pos.height / 2 - height / 2,
                    left: 0 + pos.width + posoffset
                }
            }, css = {}, pp;
            pp = dpos.split("-");
            css = variants[dpos] ? variants[dpos] : variants["bottom-left"];
            if (this.justified && this.justified.length) {
                justify(dropdown.css({
                    left: 0
                }), this.justified, boundarywidth);
            } else {
                if (this.options.preventflip !== true) {
                    var fdpos;
                    switch (this.checkBoundary(pos.left + css.left, pos.top + css.top, width, height, boundarywidth)) {
                      case "x":
                        if (this.options.preventflip !== "x") fdpos = flips["x"][dpos] || "right-top";
                        break;

                      case "y":
                        if (this.options.preventflip !== "y") fdpos = flips["y"][dpos] || "top-left";
                        break;

                      case "xy":
                        if (!this.options.preventflip) fdpos = flips["xy"][dpos] || "right-bottom";
                        break;
                    }
                    if (fdpos) {
                        pp = fdpos.split("-");
                        css = variants[fdpos] ? variants[fdpos] : variants["bottom-left"];
                        dropdown.addClass("uk-dropdown-autoflip");
                        if (this.checkBoundary(pos.left + css.left, pos.top + css.top, width, height, boundarywidth)) {
                            pp = dpos.split("-");
                            css = variants[dpos] ? variants[dpos] : variants["bottom-left"];
                        }
                    }
                }
            }
            if (width > boundarywidth) {
                dropdown.addClass("uk-dropdown-stack");
                this.trigger("stack.uk.dropdown", [ this ]);
            }
            dropdown.css(css).css("display", "").addClass("uk-dropdown-" + pp[0]);
        },
        checkBoundary: function(left, top, width, height, boundarywidth) {
            var axis = "";
            if (left < 0 || left - UI.$win.scrollLeft() + width > boundarywidth) {
                axis += "x";
            }
            if (top - UI.$win.scrollTop() < 0 || top - UI.$win.scrollTop() + height > window.innerHeight) {
                axis += "y";
            }
            return axis;
        }
    });
    UI.component("dropdownOverlay", {
        defaults: {
            justify: false,
            cls: "",
            duration: 200
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-dropdown-overlay]", context).each(function() {
                    var ele = UI.$(this);
                    if (!ele.data("dropdownOverlay")) {
                        UI.dropdownOverlay(ele, UI.Utils.options(ele.attr("data-uk-dropdown-overlay")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.justified = this.options.justify ? UI.$(this.options.justify) : false;
            this.overlay = this.element.find("uk-dropdown-overlay");
            if (!this.overlay.length) {
                this.overlay = UI.$('<div class="uk-dropdown-overlay"></div>').appendTo(this.element);
            }
            this.overlay.addClass(this.options.cls);
            this.on({
                "beforeshow.uk.dropdown": function(e, dropdown) {
                    $this.dropdown = dropdown;
                    if ($this.justified && $this.justified.length) {
                        justify($this.overlay.css({
                            display: "block",
                            marginLeft: "",
                            marginRight: ""
                        }), $this.justified, $this.justified.outerWidth());
                    }
                },
                "show.uk.dropdown": function(e, dropdown) {
                    var h = $this.dropdown.dropdown.outerHeight(true);
                    $this.dropdown.element.removeClass("uk-open");
                    $this.overlay.stop().css("display", "block").animate({
                        height: h
                    }, $this.options.duration, function() {
                        $this.dropdown.dropdown.css("visibility", "");
                        $this.dropdown.element.addClass("uk-open");
                        UI.Utils.checkDisplay($this.dropdown.dropdown, true);
                    });
                    $this.pointerleave = false;
                },
                "hide.uk.dropdown": function() {
                    $this.overlay.stop().animate({
                        height: 0
                    }, $this.options.duration);
                },
                "pointerenter.uk.dropdown": function(e, dropdown) {
                    clearTimeout($this.remainIdle);
                },
                "pointerleave.uk.dropdown": function(e, dropdown) {
                    $this.pointerleave = true;
                }
            });
            this.overlay.on({
                mouseenter: function() {
                    if ($this.remainIdle) {
                        clearTimeout($this.dropdown.remainIdle);
                        clearTimeout($this.remainIdle);
                    }
                },
                mouseleave: function() {
                    if ($this.pointerleave && active) {
                        $this.remainIdle = setTimeout(function() {
                            if (active) active.hide();
                        }, active.options.remaintime);
                    }
                }
            });
        }
    });
    function justify(ele, justifyTo, boundarywidth, offset) {
        ele = UI.$(ele);
        justifyTo = UI.$(justifyTo);
        boundarywidth = boundarywidth || window.innerWidth;
        offset = offset || ele.offset();
        if (justifyTo.length) {
            var jwidth = justifyTo.outerWidth();
            ele.css("min-width", jwidth);
            if (UI.langdirection == "right") {
                var right1 = boundarywidth - (justifyTo.offset().left + jwidth), right2 = boundarywidth - (ele.offset().left + ele.outerWidth());
                ele.css("margin-right", right1 - right2);
            } else {
                ele.css("margin-left", justifyTo.offset().left - offset.left);
            }
        }
    }
})(UIkit2);

(function(UI) {
    "use strict";
    var grids = [];
    UI.component("gridMatchHeight", {
        defaults: {
            target: false,
            row: true,
            ignorestacked: false,
            observe: false
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-grid-match]", context).each(function() {
                    var grid = UI.$(this), obj;
                    if (!grid.data("gridMatchHeight")) {
                        obj = UI.gridMatchHeight(grid, UI.Utils.options(grid.attr("data-uk-grid-match")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.columns = this.element.children();
            this.elements = this.options.target ? this.find(this.options.target) : this.columns;
            if (!this.columns.length) return;
            UI.$win.on("load resize orientationchange", function() {
                var fn = function() {
                    if ($this.element.is(":visible")) $this.match();
                };
                UI.$(function() {
                    fn();
                });
                return UI.Utils.debounce(fn, 50);
            }());
            if (this.options.observe) {
                UI.domObserve(this.element, function(e) {
                    if ($this.element.is(":visible")) $this.match();
                });
            }
            this.on("display.uk.check", function(e) {
                if (this.element.is(":visible")) this.match();
            }.bind(this));
            grids.push(this);
        },
        match: function() {
            var firstvisible = this.columns.filter(":visible:first");
            if (!firstvisible.length) return;
            var stacked = Math.ceil(100 * parseFloat(firstvisible.css("width")) / parseFloat(firstvisible.parent().css("width"))) >= 100;
            if (stacked && !this.options.ignorestacked) {
                this.revert();
            } else {
                UI.Utils.matchHeights(this.elements, this.options);
            }
            return this;
        },
        revert: function() {
            this.elements.css("min-height", "");
            return this;
        }
    });
    UI.component("gridMargin", {
        defaults: {
            cls: "uk-grid-margin",
            rowfirst: "uk-row-first"
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-grid-margin]", context).each(function() {
                    var grid = UI.$(this), obj;
                    if (!grid.data("gridMargin")) {
                        obj = UI.gridMargin(grid, UI.Utils.options(grid.attr("data-uk-grid-margin")));
                    }
                });
            });
        },
        init: function() {
            var stackMargin = UI.stackMargin(this.element, this.options);
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    var active = false, activeCount = 0, $html = UI.$html, body;
    UI.$win.on("resize orientationchange", UI.Utils.debounce(function() {
        UI.$(".uk-modal.uk-open").each(function() {
            return UI.$(this).data("modal") && UI.$(this).data("modal").resize();
        });
    }, 150));
    UI.component("modal", {
        defaults: {
            keyboard: true,
            bgclose: true,
            minScrollHeight: 150,
            center: false,
            modal: true
        },
        scrollable: false,
        transition: false,
        hasTransitioned: true,
        init: function() {
            if (!body) body = UI.$("body");
            if (!this.element.length) return;
            var $this = this;
            this.paddingdir = "padding-" + (UI.langdirection == "left" ? "right" : "left");
            this.dialog = this.find(".uk-modal-dialog");
            this.active = false;
            this.element.attr("aria-hidden", this.element.hasClass("uk-open"));
            this.on("click", ".uk-modal-close", function(e) {
                e.preventDefault();
                var modal = UI.$(e.target).closest(".uk-modal");
                if (modal[0] === $this.element[0]) $this.hide();
            }).on("click", function(e) {
                var target = UI.$(e.target);
                if (target[0] == $this.element[0] && $this.options.bgclose) {
                    $this.hide();
                }
            });
            UI.domObserve(this.element, function(e) {
                $this.resize();
            });
        },
        toggle: function() {
            return this[this.isActive() ? "hide" : "show"]();
        },
        show: function() {
            if (!this.element.length) return;
            var $this = this;
            if (this.isActive()) return;
            if (this.options.modal && active) {
                active.hide(true);
            }
            this.element.removeClass("uk-open").show();
            this.resize(true);
            if (this.options.modal) {
                active = this;
            }
            this.active = true;
            activeCount++;
            if (UI.support.transition) {
                this.hasTransitioned = false;
                this.element.one(UI.support.transition.end, function() {
                    $this.hasTransitioned = true;
                    UI.Utils.focus($this.dialog, "a[href]");
                }).addClass("uk-open");
            } else {
                this.element.addClass("uk-open");
                UI.Utils.focus(this.dialog, "a[href]");
            }
            $html.addClass("uk-modal-page").height();
            this.element.attr("aria-hidden", "false");
            this.element.trigger("show.uk.modal");
            UI.Utils.checkDisplay(this.dialog, true);
            return this;
        },
        hide: function(force) {
            if (!force && UI.support.transition && this.hasTransitioned) {
                var $this = this;
                this.one(UI.support.transition.end, function() {
                    $this._hide();
                }).removeClass("uk-open");
            } else {
                this._hide();
            }
            return this;
        },
        resize: function(force) {
            if (!this.isActive() && !force) return;
            var bodywidth = body.width();
            this.scrollbarwidth = window.innerWidth - bodywidth;
            body.css(this.paddingdir, this.scrollbarwidth);
            this.element.css("overflow-y", this.scrollbarwidth ? "scroll" : "auto");
            if (!this.updateScrollable() && this.options.center) {
                var dh = this.dialog.outerHeight(), pad = parseInt(this.dialog.css("margin-top"), 10) + parseInt(this.dialog.css("margin-bottom"), 10);
                if (dh + pad < window.innerHeight) {
                    this.dialog.css({
                        top: window.innerHeight / 2 - dh / 2 - pad
                    });
                } else {
                    this.dialog.css({
                        top: ""
                    });
                }
            }
        },
        updateScrollable: function() {
            var scrollable = this.dialog.find(".uk-overflow-container:visible:first");
            if (scrollable.length) {
                scrollable.css("height", 0);
                var offset = Math.abs(parseInt(this.dialog.css("margin-top"), 10)), dh = this.dialog.outerHeight(), wh = window.innerHeight, h = wh - 2 * (offset < 20 ? 20 : offset) - dh;
                scrollable.css({
                    maxHeight: h < this.options.minScrollHeight ? "" : h,
                    height: ""
                });
                return true;
            }
            return false;
        },
        _hide: function() {
            this.active = false;
            if (activeCount > 0) activeCount--; else activeCount = 0;
            this.element.hide().removeClass("uk-open");
            this.element.attr("aria-hidden", "true");
            if (!activeCount) {
                $html.removeClass("uk-modal-page");
                body.css(this.paddingdir, "");
            }
            if (active === this) active = false;
            this.trigger("hide.uk.modal");
        },
        isActive: function() {
            return this.element.hasClass("uk-open");
        }
    });
    UI.component("modalTrigger", {
        boot: function() {
            UI.$html.on("click.modal.uikit", "[data-uk-modal]", function(e) {
                var ele = UI.$(this);
                if (ele.is("a")) {
                    e.preventDefault();
                }
                if (!ele.data("modalTrigger")) {
                    var modal = UI.modalTrigger(ele, UI.Utils.options(ele.attr("data-uk-modal")));
                    modal.show();
                }
            });
            UI.$html.on("keydown.modal.uikit", function(e) {
                if (active && e.keyCode === 27 && active.options.keyboard) {
                    e.preventDefault();
                    active.hide();
                }
            });
        },
        init: function() {
            var $this = this;
            this.options = UI.$.extend({
                target: $this.element.is("a") ? $this.element.attr("href") : false
            }, this.options);
            this.modal = UI.modal(this.options.target, this.options);
            this.on("click", function(e) {
                e.preventDefault();
                $this.show();
            });
            this.proxy(this.modal, "show hide isActive");
        }
    });
    UI.modal.dialog = function(content, options) {
        var modal = UI.modal(UI.$(UI.modal.dialog.template).appendTo("body"), options);
        modal.on("hide.uk.modal", function() {
            if (modal.persist) {
                modal.persist.appendTo(modal.persist.data("modalPersistParent"));
                modal.persist = false;
            }
            modal.element.remove();
        });
        setContent(content, modal);
        return modal;
    };
    UI.modal.dialog.template = '<div class="uk-modal"><div class="uk-modal-dialog" style="min-height:0;"></div></div>';
    UI.modal.alert = function(content, options) {
        options = UI.$.extend(true, {
            bgclose: false,
            keyboard: false,
            modal: false,
            labels: UI.modal.labels
        }, options);
        var modal = UI.modal.dialog([ '<div class="uk-margin uk-modal-content">' + String(content) + "</div>", '<div class="uk-modal-footer uk-text-right"><button class="uk-button uk-button-primary uk-modal-close">' + options.labels.Ok + "</button></div>" ].join(""), options);
        modal.on("show.uk.modal", function() {
            setTimeout(function() {
                modal.element.find("button:first").focus();
            }, 50);
        });
        return modal.show();
    };
    UI.modal.confirm = function(content, onconfirm, oncancel) {
        var options = arguments.length > 1 && arguments[arguments.length - 1] ? arguments[arguments.length - 1] : {};
        onconfirm = UI.$.isFunction(onconfirm) ? onconfirm : function() {};
        oncancel = UI.$.isFunction(oncancel) ? oncancel : function() {};
        options = UI.$.extend(true, {
            bgclose: false,
            keyboard: false,
            modal: false,
            labels: UI.modal.labels
        }, UI.$.isFunction(options) ? {} : options);
        var modal = UI.modal.dialog([ '<div class="uk-margin uk-modal-content">' + String(content) + "</div>", '<div class="uk-modal-footer uk-text-right"><button class="uk-button js-modal-confirm-cancel">' + options.labels.Cancel + '</button> <button class="uk-button uk-button-primary js-modal-confirm">' + options.labels.Ok + "</button></div>" ].join(""), options);
        modal.element.find(".js-modal-confirm, .js-modal-confirm-cancel").on("click", function() {
            UI.$(this).is(".js-modal-confirm") ? onconfirm() : oncancel();
            modal.hide();
        });
        modal.on("show.uk.modal", function() {
            setTimeout(function() {
                modal.element.find(".js-modal-confirm").focus();
            }, 50);
        });
        return modal.show();
    };
    UI.modal.prompt = function(text, value, onsubmit, options) {
        onsubmit = UI.$.isFunction(onsubmit) ? onsubmit : function(value) {};
        options = UI.$.extend(true, {
            bgclose: false,
            keyboard: false,
            modal: false,
            labels: UI.modal.labels
        }, options);
        var modal = UI.modal.dialog([ text ? '<div class="uk-modal-content uk-form">' + String(text) + "</div>" : "", '<div class="uk-margin-small-top uk-modal-content uk-form"><p><input type="text" class="uk-width-1-1"></p></div>', '<div class="uk-modal-footer uk-text-right"><button class="uk-button uk-modal-close">' + options.labels.Cancel + '</button> <button class="uk-button uk-button-primary js-modal-ok">' + options.labels.Ok + "</button></div>" ].join(""), options), input = modal.element.find("input[type='text']").val(value || "").on("keyup", function(e) {
            if (e.keyCode == 13) {
                modal.element.find(".js-modal-ok").trigger("click");
            }
        });
        modal.element.find(".js-modal-ok").on("click", function() {
            if (onsubmit(input.val()) !== false) {
                modal.hide();
            }
        });
        return modal.show();
    };
    UI.modal.blockUI = function(content, options) {
        var modal = UI.modal.dialog([ '<div class="uk-margin uk-modal-content">' + String(content || '<div class="uk-text-center">...</div>') + "</div>" ].join(""), UI.$.extend({
            bgclose: false,
            keyboard: false,
            modal: false
        }, options));
        modal.content = modal.element.find(".uk-modal-content:first");
        return modal.show();
    };
    UI.modal.labels = {
        Ok: "Ok",
        Cancel: "Cancel"
    };
    function setContent(content, modal) {
        if (!modal) return;
        if (typeof content === "object") {
            content = content instanceof jQuery ? content : UI.$(content);
            if (content.parent().length) {
                modal.persist = content;
                modal.persist.data("modalPersistParent", content.parent());
            }
        } else if (typeof content === "string" || typeof content === "number") {
            content = UI.$("<div></div>").html(content);
        } else {
            content = UI.$("<div></div>").html("UIkit2.modal Error: Unsupported data type: " + typeof content);
        }
        content.appendTo(modal.element.find(".uk-modal-dialog"));
        return modal;
    }
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("nav", {
        defaults: {
            toggle: '>li.uk-parent > a[href="#"]',
            lists: ">li.uk-parent > ul",
            multiple: false
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-nav]", context).each(function() {
                    var nav = UI.$(this);
                    if (!nav.data("nav")) {
                        var obj = UI.nav(nav, UI.Utils.options(nav.attr("data-uk-nav")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.on("click.uk.nav", this.options.toggle, function(e) {
                e.preventDefault();
                var ele = UI.$(this);
                $this.open(ele.parent()[0] == $this.element[0] ? ele : ele.parent("li"));
            });
            this.update();
            UI.domObserve(this.element, function(e) {
                if ($this.element.find($this.options.lists).not("[role]").length) {
                    $this.update();
                }
            });
        },
        update: function() {
            var $this = this;
            this.find(this.options.lists).each(function() {
                var $ele = UI.$(this).attr("role", "menu"), parent = $ele.closest("li"), active = parent.hasClass("uk-active");
                if (!parent.data("list-container")) {
                    $ele.wrap('<div style="overflow:hidden;height:0;position:relative;"></div>');
                    parent.data("list-container", $ele.parent()[active ? "removeClass" : "addClass"]("uk-hidden"));
                }
                parent.attr("aria-expanded", parent.hasClass("uk-open"));
                if (active) $this.open(parent, true);
            });
        },
        open: function(li, noanimation) {
            var $this = this, element = this.element, $li = UI.$(li), $container = $li.data("list-container");
            if (!this.options.multiple) {
                element.children(".uk-open").not(li).each(function() {
                    var ele = UI.$(this);
                    if (ele.data("list-container")) {
                        ele.data("list-container").stop().animate({
                            height: 0
                        }, function() {
                            UI.$(this).parent().removeClass("uk-open").end().addClass("uk-hidden");
                        });
                    }
                });
            }
            $li.toggleClass("uk-open");
            $li.attr("aria-expanded", $li.hasClass("uk-open"));
            if ($container) {
                if ($li.hasClass("uk-open")) {
                    $container.removeClass("uk-hidden");
                }
                if (noanimation) {
                    $container.stop().height($li.hasClass("uk-open") ? "auto" : 0);
                    if (!$li.hasClass("uk-open")) {
                        $container.addClass("uk-hidden");
                    }
                    this.trigger("display.uk.check");
                } else {
                    $container.stop().animate({
                        height: $li.hasClass("uk-open") ? getHeight($container.find("ul:first")) : 0
                    }, function() {
                        if (!$li.hasClass("uk-open")) {
                            $container.addClass("uk-hidden");
                        } else {
                            $container.css("height", "");
                        }
                        $this.trigger("display.uk.check");
                    });
                }
            }
        }
    });
    function getHeight(ele) {
        var $ele = UI.$(ele), height = "auto";
        if ($ele.is(":visible")) {
            height = $ele.outerHeight();
        } else {
            var tmp = {
                position: $ele.css("position"),
                visibility: $ele.css("visibility"),
                display: $ele.css("display")
            };
            height = $ele.css({
                position: "absolute",
                visibility: "hidden",
                display: "block"
            }).outerHeight();
            $ele.css(tmp);
        }
        return height;
    }
})(UIkit2);

(function(UI) {
    "use strict";
    var scrollpos = {
        x: window.scrollX,
        y: window.scrollY
    }, $win = UI.$win, $doc = UI.$doc, $html = UI.$html, Offcanvas = {
        show: function(element, options) {
            element = UI.$(element);
            if (!element.length) return;
            options = UI.$.extend({
                mode: "push"
            }, options);
            var $body = UI.$("body"), bar = element.find(".uk-offcanvas-bar:first"), rtl = UI.langdirection == "right", flip = bar.hasClass("uk-offcanvas-bar-flip") ? -1 : 1, dir = flip * (rtl ? -1 : 1), scrollbarwidth = window.innerWidth - $body.width();
            scrollpos = {
                x: window.pageXOffset,
                y: window.pageYOffset
            };
            bar.attr("mode", options.mode);
            element.addClass("uk-active");
            $body.css({
                width: window.innerWidth - scrollbarwidth,
                height: window.innerHeight
            }).addClass("uk-offcanvas-page");
            if (options.mode == "push" || options.mode == "reveal") {
                $body.css(rtl ? "margin-right" : "margin-left", (rtl ? -1 : 1) * (bar.outerWidth() * dir));
            }
            if (options.mode == "reveal") {
                bar.css("clip", "rect(0, " + bar.outerWidth() + "px, 100vh, 0)");
            }
            $html.css("margin-top", scrollpos.y * -1).width();
            bar.addClass("uk-offcanvas-bar-show");
            this._initElement(element);
            bar.trigger("show.uk.offcanvas", [ element, bar ]);
            element.attr("aria-hidden", "false");
        },
        hide: function(force) {
            var $body = UI.$("body"), panel = UI.$(".uk-offcanvas.uk-active"), rtl = UI.langdirection == "right", bar = panel.find(".uk-offcanvas-bar:first"), finalize = function() {
                $body.removeClass("uk-offcanvas-page").css({
                    width: "",
                    height: "",
                    marginLeft: "",
                    marginRight: ""
                });
                panel.removeClass("uk-active");
                bar.removeClass("uk-offcanvas-bar-show");
                $html.css("margin-top", "");
                window.scrollTo(scrollpos.x, scrollpos.y);
                bar.trigger("hide.uk.offcanvas", [ panel, bar ]);
                panel.attr("aria-hidden", "true");
            };
            if (!panel.length) return;
            if (bar.attr("mode") == "none") force = true;
            if (UI.support.transition && !force) {
                $body.one(UI.support.transition.end, function() {
                    finalize();
                }).css(rtl ? "margin-right" : "margin-left", "");
                if (bar.attr("mode") == "reveal") {
                    bar.css("clip", "");
                }
                setTimeout(function() {
                    bar.removeClass("uk-offcanvas-bar-show");
                }, 0);
            } else {
                finalize();
            }
        },
        _initElement: function(element) {
            if (element.data("OffcanvasInit")) return;
            element.on("click.uk.offcanvas swipeRight.uk.offcanvas swipeLeft.uk.offcanvas", function(e) {
                var target = UI.$(e.target);
                if (e.type.match(/swipe/)) {
                    if (target.parents(".uk-offcanvas-bar:first").length) return;
                } else {
                    if (!target.hasClass("uk-offcanvas-close")) {
                        if (target.hasClass("uk-offcanvas-bar")) return;
                        if (target.parents(".uk-offcanvas-bar:first").length) return;
                    }
                }
                e.stopImmediatePropagation();
                Offcanvas.hide();
            });
            element.on("click", 'a[href*="#"]', function(e) {
                var link = UI.$(this), href = link.attr("href");
                if (href == "#") {
                    return;
                }
                UI.$doc.one("hide.uk.offcanvas", function() {
                    var target;
                    try {
                        target = UI.$(link[0].hash);
                    } catch (e) {
                        target = "";
                    }
                    if (!target.length) {
                        target = UI.$('[name="' + link[0].hash.replace("#", "") + '"]');
                    }
                    if (target.length && UI.Utils.scrollToElement) {
                        UI.Utils.scrollToElement(target, UI.Utils.options(link.attr("data-uk-smooth-scroll") || "{}"));
                    } else {
                        window.location.href = href;
                    }
                });
                Offcanvas.hide();
            });
            element.data("OffcanvasInit", true);
        }
    };
    UI.component("offcanvasTrigger", {
        boot: function() {
            $html.on("click.offcanvas.uikit", "[data-uk-offcanvas]", function(e) {
                e.preventDefault();
                var ele = UI.$(this);
                if (!ele.data("offcanvasTrigger")) {
                    var obj = UI.offcanvasTrigger(ele, UI.Utils.options(ele.attr("data-uk-offcanvas")));
                    ele.trigger("click");
                }
            });
            $html.on("keydown.uk.offcanvas", function(e) {
                if (e.keyCode === 27) {
                    Offcanvas.hide();
                }
            });
        },
        init: function() {
            var $this = this;
            this.options = UI.$.extend({
                target: $this.element.is("a") ? $this.element.attr("href") : false,
                mode: "push"
            }, this.options);
            this.on("click", function(e) {
                e.preventDefault();
                Offcanvas.show($this.options.target, $this.options);
            });
        }
    });
    UI.offcanvas = Offcanvas;
})(UIkit2);

(function(UI) {
    "use strict";
    var Animations;
    UI.component("switcher", {
        defaults: {
            connect: false,
            toggle: ">*",
            active: 0,
            animation: false,
            duration: 200,
            swiping: true
        },
        animating: false,
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-switcher]", context).each(function() {
                    var switcher = UI.$(this);
                    if (!switcher.data("switcher")) {
                        var obj = UI.switcher(switcher, UI.Utils.options(switcher.attr("data-uk-switcher")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.on("click.uk.switcher", this.options.toggle, function(e) {
                e.preventDefault();
                $this.show(this);
            });
            if (!this.options.connect) {
                return;
            }
            this.connect = UI.$(this.options.connect);
            if (!this.connect.length) {
                return;
            }
            this.connect.on("click.uk.switcher", "[data-uk-switcher-item]", function(e) {
                e.preventDefault();
                var item = UI.$(this).attr("data-uk-switcher-item");
                if ($this.index == item) return;
                switch (item) {
                  case "next":
                  case "previous":
                    $this.show($this.index + (item == "next" ? 1 : -1));
                    break;

                  default:
                    $this.show(parseInt(item, 10));
                }
            });
            if (this.options.swiping) {
                this.connect.on("swipeRight swipeLeft", function(e) {
                    e.preventDefault();
                    if (!window.getSelection().toString()) {
                        $this.show($this.index + (e.type == "swipeLeft" ? 1 : -1));
                    }
                });
            }
            this.update();
        },
        update: function() {
            this.connect.children().removeClass("uk-active").attr("aria-hidden", "true");
            var toggles = this.find(this.options.toggle), active = toggles.filter(".uk-active");
            if (active.length) {
                this.show(active, false);
            } else {
                if (this.options.active === false) return;
                active = toggles.eq(this.options.active);
                this.show(active.length ? active : toggles.eq(0), false);
            }
            toggles.not(active).attr("aria-expanded", "false");
            active.attr("aria-expanded", "true");
        },
        show: function(tab, animate) {
            if (this.animating) {
                return;
            }
            var toggles = this.find(this.options.toggle);
            if (isNaN(tab)) {
                tab = UI.$(tab);
            } else {
                tab = tab < 0 ? toggles.length - 1 : tab;
                tab = toggles.eq(toggles[tab] ? tab : 0);
            }
            var $this = this, active = UI.$(tab), animation = Animations[this.options.animation] || function(current, next) {
                if (!$this.options.animation) {
                    return Animations.none.apply($this);
                }
                var anim = $this.options.animation.split(",");
                if (anim.length == 1) {
                    anim[1] = anim[0];
                }
                anim[0] = anim[0].trim();
                anim[1] = anim[1].trim();
                return coreAnimation.apply($this, [ anim, current, next ]);
            };
            if (animate === false || !UI.support.animation) {
                animation = Animations.none;
            }
            if (active.hasClass("uk-disabled")) return;
            toggles.attr("aria-expanded", "false");
            active.attr("aria-expanded", "true");
            toggles.filter(".uk-active").removeClass("uk-active");
            active.addClass("uk-active");
            if (this.options.connect && this.connect.length) {
                this.index = this.find(this.options.toggle).index(active);
                if (this.index == -1) {
                    this.index = 0;
                }
                this.connect.each(function() {
                    var container = UI.$(this), children = UI.$(container.children()), current = UI.$(children.filter(".uk-active")), next = UI.$(children.eq($this.index));
                    $this.animating = true;
                    animation.apply($this, [ current, next ]).then(function() {
                        current.removeClass("uk-active");
                        next.addClass("uk-active");
                        current.attr("aria-hidden", "true");
                        next.attr("aria-hidden", "false");
                        UI.Utils.checkDisplay(next, true);
                        $this.animating = false;
                    });
                });
            }
            this.trigger("show.uk.switcher", [ active ]);
        }
    });
    Animations = {
        none: function() {
            var d = UI.$.Deferred();
            d.resolve();
            return d.promise();
        },
        fade: function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-fade", current, next ]);
        },
        "slide-bottom": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-bottom", current, next ]);
        },
        "slide-top": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-top", current, next ]);
        },
        "slide-vertical": function(current, next, dir) {
            var anim = [ "uk-animation-slide-top", "uk-animation-slide-bottom" ];
            if (current && current.index() > next.index()) {
                anim.reverse();
            }
            return coreAnimation.apply(this, [ anim, current, next ]);
        },
        "slide-left": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-left", current, next ]);
        },
        "slide-right": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-right", current, next ]);
        },
        "slide-horizontal": function(current, next, dir) {
            var anim = [ "uk-animation-slide-right", "uk-animation-slide-left" ];
            if (current && current.index() > next.index()) {
                anim.reverse();
            }
            return coreAnimation.apply(this, [ anim, current, next ]);
        },
        scale: function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-scale-up", current, next ]);
        }
    };
    UI.switcher.animations = Animations;
    function coreAnimation(cls, current, next) {
        var d = UI.$.Deferred(), clsIn = cls, clsOut = cls, release;
        if (next[0] === current[0]) {
            d.resolve();
            return d.promise();
        }
        if (typeof cls == "object") {
            clsIn = cls[0];
            clsOut = cls[1] || cls[0];
        }
        UI.$body.css("overflow-x", "hidden");
        release = function() {
            if (current) current.hide().removeClass("uk-active " + clsOut + " uk-animation-reverse");
            next.addClass(clsIn).one(UI.support.animation.end, function() {
                setTimeout(function() {
                    next.removeClass("" + clsIn + "").css({
                        opacity: "",
                        display: ""
                    });
                }, 0);
                d.resolve();
                UI.$body.css("overflow-x", "");
                if (current) current.css({
                    opacity: "",
                    display: ""
                });
            }.bind(this)).show();
        };
        next.css("animation-duration", this.options.duration + "ms");
        if (current && current.length) {
            current.css("animation-duration", this.options.duration + "ms");
            current.css("display", "none").addClass(clsOut + " uk-animation-reverse").one(UI.support.animation.end, function() {
                release();
            }.bind(this)).css("display", "");
        } else {
            next.addClass("uk-active");
            release();
        }
        return d.promise();
    }
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("tab", {
        defaults: {
            target: ">li:not(.uk-tab-responsive, .uk-disabled)",
            connect: false,
            active: 0,
            animation: false,
            duration: 200,
            swiping: true
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-tab]", context).each(function() {
                    var tab = UI.$(this);
                    if (!tab.data("tab")) {
                        var obj = UI.tab(tab, UI.Utils.options(tab.attr("data-uk-tab")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.current = false;
            this.on("click.uk.tab", this.options.target, function(e) {
                e.preventDefault();
                if ($this.switcher && $this.switcher.animating) {
                    return;
                }
                var current = $this.find($this.options.target).not(this);
                current.removeClass("uk-active").blur();
                $this.trigger("change.uk.tab", [ UI.$(this).addClass("uk-active"), $this.current ]);
                $this.current = UI.$(this);
                if (!$this.options.connect) {
                    current.attr("aria-expanded", "false");
                    UI.$(this).attr("aria-expanded", "true");
                }
            });
            if (this.options.connect) {
                this.connect = UI.$(this.options.connect);
            }
            this.responsivetab = UI.$('<li class="uk-tab-responsive uk-active"><a></a></li>').append('<div class="uk-dropdown uk-dropdown-small"><ul class="uk-nav uk-nav-dropdown"></ul><div>');
            this.responsivetab.dropdown = this.responsivetab.find(".uk-dropdown");
            this.responsivetab.lst = this.responsivetab.dropdown.find("ul");
            this.responsivetab.caption = this.responsivetab.find("a:first");
            if (this.element.hasClass("uk-tab-bottom")) this.responsivetab.dropdown.addClass("uk-dropdown-up");
            this.responsivetab.lst.on("click.uk.tab", "a", function(e) {
                e.preventDefault();
                e.stopPropagation();
                var link = UI.$(this);
                $this.element.children("li:not(.uk-tab-responsive)").eq(link.data("index")).trigger("click");
            });
            this.on("show.uk.switcher change.uk.tab", function(e, tab) {
                $this.responsivetab.caption.html(tab.text());
            });
            this.element.append(this.responsivetab);
            if (this.options.connect) {
                this.switcher = UI.switcher(this.element, {
                    toggle: ">li:not(.uk-tab-responsive)",
                    connect: this.options.connect,
                    active: this.options.active,
                    animation: this.options.animation,
                    duration: this.options.duration,
                    swiping: this.options.swiping
                });
            }
            UI.dropdown(this.responsivetab, {
                mode: "click",
                preventflip: "y"
            });
            $this.trigger("change.uk.tab", [ this.element.find(this.options.target).not(".uk-tab-responsive").filter(".uk-active") ]);
            this.check();
            UI.$win.on("resize orientationchange", UI.Utils.debounce(function() {
                if ($this.element.is(":visible")) $this.check();
            }, 100));
            this.on("display.uk.check", function() {
                if ($this.element.is(":visible")) $this.check();
            });
        },
        check: function() {
            var children = this.element.children("li:not(.uk-tab-responsive)").removeClass("uk-hidden");
            if (!children.length) {
                this.responsivetab.addClass("uk-hidden");
                return;
            }
            var top = children.eq(0).offset().top + Math.ceil(children.eq(0).height() / 2), doresponsive = false, item, link, clone;
            this.responsivetab.lst.empty();
            children.each(function() {
                if (UI.$(this).offset().top > top) {
                    doresponsive = true;
                }
            });
            if (doresponsive) {
                for (var i = 0; i < children.length; i++) {
                    item = UI.$(children.eq(i));
                    link = item.find("a");
                    if (item.css("float") != "none" && !item.attr("uk-dropdown")) {
                        if (!item.hasClass("uk-disabled")) {
                            clone = UI.$(item[0].outerHTML);
                            clone.find("a").data("index", i);
                            this.responsivetab.lst.append(clone);
                        }
                        item.addClass("uk-hidden");
                    }
                }
            }
            this.responsivetab[this.responsivetab.lst.children("li").length ? "removeClass" : "addClass"]("uk-hidden");
        }
    });
})(UIkit2);

(function(UI) {
    "use strict";
    UI.component("cover", {
        defaults: {
            automute: true
        },
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-cover]", context).each(function() {
                    var ele = UI.$(this);
                    if (!ele.data("cover")) {
                        var plugin = UI.cover(ele, UI.Utils.options(ele.attr("data-uk-cover")));
                    }
                });
            });
        },
        init: function() {
            this.parent = this.element.parent();
            UI.$win.on("load resize orientationchange", UI.Utils.debounce(function() {
                this.check();
            }.bind(this), 100));
            this.on("display.uk.check", function(e) {
                if (this.element.is(":visible")) this.check();
            }.bind(this));
            this.check();
            if (this.element.is("iframe") && this.options.automute) {
                var src = this.element.attr("src");
                this.element.attr("src", "").on("load", function() {
                    this.contentWindow.postMessage('{ "event": "command", "func": "mute", "method":"setVolume", "value":0}', "*");
                }).attr("src", [ src, src.indexOf("?") > -1 ? "&" : "?", "enablejsapi=1&api=1" ].join(""));
            }
        },
        check: function() {
            this.element.css({
                width: "",
                height: ""
            });
            this.dimension = {
                w: this.element.width(),
                h: this.element.height()
            };
            if (this.element.attr("width") && !isNaN(this.element.attr("width"))) {
                this.dimension.w = this.element.attr("width");
            }
            if (this.element.attr("height") && !isNaN(this.element.attr("height"))) {
                this.dimension.h = this.element.attr("height");
            }
            this.ratio = this.dimension.w / this.dimension.h;
            var w = this.parent.width(), h = this.parent.height(), width, height;
            if (w / this.ratio < h) {
                width = Math.ceil(h * this.ratio);
                height = h;
            } else {
                width = w;
                height = Math.ceil(w / this.ratio);
            }
            this.element.css({
                width: width,
                height: height
            });
        }
    });
})(UIkit2);

(function(addon) {
    var component;
    if (window.UIkit2) {
        component = addon(UIkit2);
    }
    if (typeof define == "function" && define.amd) {
        define("uikit-slideset", [ "uikit" ], function() {
            return component || addon(UIkit2);
        });
    }
})(function(UI) {
    "use strict";
    var Animations;
    UI.component("slideset", {
        defaults: {
            default: 1,
            animation: "fade",
            duration: 200,
            filter: "",
            delay: false,
            controls: false,
            autoplay: false,
            autoplayInterval: 7e3,
            pauseOnHover: true
        },
        sets: [],
        boot: function() {
            UI.ready(function(context) {
                UI.$("[data-uk-slideset]", context).each(function() {
                    var ele = UI.$(this);
                    if (!ele.data("slideset")) {
                        UI.slideset(ele, UI.Utils.options(ele.attr("data-uk-slideset")));
                    }
                });
            });
        },
        init: function() {
            var $this = this;
            this.activeSet = false;
            this.list = this.element.find(".uk-slideset");
            this.nav = this.element.find(".uk-slideset-nav");
            this.controls = this.options.controls ? UI.$(this.options.controls) : this.element;
            UI.$win.on("resize load", UI.Utils.debounce(function() {
                $this.update();
            }, 100));
            $this.list.addClass("uk-grid-width-1-" + $this.options.default);
            [ "xlarge", "large", "medium", "small" ].forEach(function(bp) {
                if (!$this.options[bp]) {
                    return;
                }
                $this.list.addClass("uk-grid-width-" + bp + "-1-" + $this.options[bp]);
            });
            this.on("click.uk.slideset", "[data-uk-slideset-item]", function(e) {
                e.preventDefault();
                if ($this.animating) {
                    return;
                }
                var set = UI.$(this).attr("data-uk-slideset-item");
                if ($this.activeSet === set) return;
                switch (set) {
                  case "next":
                  case "previous":
                    $this[set == "next" ? "next" : "previous"]();
                    break;

                  default:
                    $this.show(parseInt(set, 10));
                }
            });
            this.controls.on("click.uk.slideset", "[data-uk-filter]", function(e) {
                var ele = UI.$(this);
                if (ele.parent().hasClass("uk-slideset")) {
                    return;
                }
                e.preventDefault();
                if ($this.animating || $this.currentFilter == ele.attr("data-uk-filter")) {
                    return;
                }
                $this.updateFilter(ele.attr("data-uk-filter"));
                $this._hide().then(function() {
                    $this.update(true, true);
                });
            });
            this.on("swipeRight swipeLeft", function(e) {
                $this[e.type == "swipeLeft" ? "next" : "previous"]();
            });
            this.updateFilter(this.options.filter);
            this.update();
            this.element.on({
                mouseenter: function() {
                    if ($this.options.pauseOnHover) $this.hovering = true;
                },
                mouseleave: function() {
                    $this.hovering = false;
                }
            });
            if (this.options.autoplay) {
                this.start();
            }
            UI.domObserve(this.list, function(e) {
                if ($this.list.children(":visible:not(.uk-active)").length) {
                    $this.update(false, true);
                }
            });
        },
        update: function(animate, force) {
            var visible = this.visible, i;
            this.visible = this.getVisibleOnCurrenBreakpoint();
            if (visible == this.visible && !force) {
                return;
            }
            this.children = this.list.children().hide();
            this.items = this.getItems();
            this.sets = array_chunk(this.items, this.visible);
            for (i = 0; i < this.sets.length; i++) {
                this.sets[i].css({
                    display: "none"
                });
            }
            if (this.nav.length && this.nav.empty()) {
                for (i = 0; i < this.sets.length; i++) {
                    this.nav.append('<li data-uk-slideset-item="' + i + '"><a></a></li>');
                }
                this.nav[this.nav.children().length == 1 ? "addClass" : "removeClass"]("uk-invisible");
            }
            this.activeSet = false;
            this.show(0, !animate);
        },
        updateFilter: function(currentfilter) {
            var $this = this, filter;
            this.currentFilter = currentfilter;
            this.controls.find("[data-uk-filter]").each(function() {
                filter = UI.$(this);
                if (!filter.parent().hasClass("uk-slideset")) {
                    if (filter.attr("data-uk-filter") == $this.currentFilter) {
                        filter.addClass("uk-active");
                    } else {
                        filter.removeClass("uk-active");
                    }
                }
            });
        },
        getVisibleOnCurrenBreakpoint: function() {
            var breakpoint = null, tmp = UI.$('<div style="position:absolute;height:1px;top:-1000px;width:100px"><div></div></div>').appendTo("body"), testdiv = tmp.children().eq(0), breakpoints = this.options;
            [ "xlarge", "large", "medium", "small" ].forEach(function(bp) {
                if (!breakpoints[bp] || breakpoint) {
                    return;
                }
                tmp.attr("class", "uk-grid-width-" + bp + "-1-2").width();
                if (testdiv.width() == 50) {
                    breakpoint = bp;
                }
            });
            tmp.remove();
            return this.options[breakpoint] || this.options["default"];
        },
        getItems: function() {
            var items = [], filter;
            if (this.currentFilter) {
                filter = this.currentFilter || [];
                if (typeof filter === "string") {
                    filter = filter.split(/,/).map(function(item) {
                        return item.trim();
                    });
                }
                this.children.each(function(index) {
                    var ele = UI.$(this), f = ele.attr("data-uk-filter"), infilter = filter.length ? false : true;
                    if (f) {
                        f = f.split(/,/).map(function(item) {
                            return item.trim();
                        });
                        filter.forEach(function(item) {
                            if (f.indexOf(item) > -1) infilter = true;
                        });
                    }
                    if (infilter) items.push(ele[0]);
                });
                items = UI.$(items);
            } else {
                items = this.list.children();
            }
            return items;
        },
        show: function(setIndex, noanimate, dir) {
            var $this = this;
            if (this.activeSet === setIndex || this.animating) {
                return;
            }
            dir = dir || (setIndex < this.activeSet ? -1 : 1);
            var current = this.sets[this.activeSet] || [], next = this.sets[setIndex], animation = this._getAnimation();
            if (noanimate || !UI.support.animation) {
                animation = Animations.none;
            }
            this.animating = true;
            if (this.nav.length) {
                this.nav.children().removeClass("uk-active").eq(setIndex).addClass("uk-active");
            }
            animation.apply($this, [ current, next, dir ]).then(function() {
                UI.Utils.checkDisplay(next, true);
                $this.children.hide().removeClass("uk-active");
                next.addClass("uk-active").css({
                    display: "",
                    opacity: ""
                });
                $this.animating = false;
                $this.activeSet = setIndex;
                UI.Utils.checkDisplay(next, true);
                $this.trigger("show.uk.slideset", [ next ]);
            });
        },
        _getAnimation: function() {
            var animation = Animations[this.options.animation] || Animations.none;
            if (!UI.support.animation) {
                animation = Animations.none;
            }
            return animation;
        },
        _hide: function() {
            var $this = this, current = this.sets[this.activeSet] || [], animation = this._getAnimation();
            this.animating = true;
            return animation.apply($this, [ current, [], 1 ]).then(function() {
                $this.animating = false;
            });
        },
        next: function() {
            this.show(this.sets[this.activeSet + 1] ? this.activeSet + 1 : 0, false, 1);
        },
        previous: function() {
            this.show(this.sets[this.activeSet - 1] ? this.activeSet - 1 : this.sets.length - 1, false, -1);
        },
        start: function() {
            this.stop();
            var $this = this;
            this.interval = setInterval(function() {
                if (!$this.hovering && !$this.animating) $this.next();
            }, this.options.autoplayInterval);
        },
        stop: function() {
            if (this.interval) clearInterval(this.interval);
        }
    });
    Animations = {
        none: function() {
            var d = UI.$.Deferred();
            d.resolve();
            return d.promise();
        },
        fade: function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-fade", current, next ]);
        },
        "slide-bottom": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-bottom", current, next ]);
        },
        "slide-top": function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-slide-top", current, next ]);
        },
        "slide-vertical": function(current, next, dir) {
            var anim = [ "uk-animation-slide-top", "uk-animation-slide-bottom" ];
            if (dir == -1) {
                anim.reverse();
            }
            return coreAnimation.apply(this, [ anim, current, next ]);
        },
        "slide-horizontal": function(current, next, dir) {
            var anim = [ "uk-animation-slide-right", "uk-animation-slide-left" ];
            if (dir == -1) {
                anim.reverse();
            }
            return coreAnimation.apply(this, [ anim, current, next, dir ]);
        },
        scale: function(current, next) {
            return coreAnimation.apply(this, [ "uk-animation-scale-up", current, next ]);
        }
    };
    UI.slideset.animations = Animations;
    function coreAnimation(cls, current, next, dir) {
        var d = UI.$.Deferred(), delay = this.options.delay === false ? Math.floor(this.options.duration / 2) : this.options.delay, $this = this, clsIn, clsOut, release, i;
        dir = dir || 1;
        this.element.css("min-height", this.element.height());
        if (next[0] === current[0]) {
            d.resolve();
            return d.promise();
        }
        if (typeof cls == "object") {
            clsIn = cls[0];
            clsOut = cls[1] || cls[0];
        } else {
            clsIn = cls;
            clsOut = clsIn;
        }
        UI.$body.css("overflow-x", "hidden");
        release = function() {
            if (current && current.length) {
                current.hide().removeClass(clsOut + " uk-animation-reverse").css({
                    opacity: "",
                    "animation-delay": "",
                    animation: ""
                });
            }
            if (!next.length) {
                d.resolve();
                return;
            }
            for (i = 0; i < next.length; i++) {
                next.eq(dir == 1 ? i : next.length - i - 1).css("animation-delay", i * delay + "ms");
            }
            var finish = function() {
                next.removeClass("" + clsIn + "").css({
                    opacity: "",
                    display: "",
                    "animation-delay": "",
                    animation: ""
                });
                d.resolve();
                UI.$body.css("overflow-x", "");
                $this.element.css("min-height", "");
                finish = false;
            };
            next.addClass(clsIn)[dir == 1 ? "last" : "first"]().one(UI.support.animation.end, function() {
                if (finish) finish();
            }).end().css("display", "");
            setTimeout(function() {
                if (finish) finish();
            }, next.length * delay * 2);
        };
        if (next.length) {
            next.css("animation-duration", this.options.duration + "ms");
        }
        if (current && current.length) {
            current.css("animation-duration", this.options.duration + "ms")[dir == 1 ? "last" : "first"]().one(UI.support.animation.end, function() {
                release();
            });
            for (i = 0; i < current.length; i++) {
                (function(index, ele) {
                    setTimeout(function() {
                        ele.css("display", "none").css("display", "").css("opacity", 0).on(UI.support.animation.end, function() {
                            ele.removeClass(clsOut);
                        }).addClass(clsOut + " uk-animation-reverse");
                    }.bind(this), i * delay);
                })(i, current.eq(dir == 1 ? i : current.length - i - 1));
            }
        } else {
            release();
        }
        return d.promise();
    }
    function array_chunk(input, size) {
        var x, i = 0, c = -1, l = input.length || 0, n = [];
        if (size < 1) return null;
        while (i < l) {
            x = i % size;
            if (x) {
                n[c][x] = input[i];
            } else {
                n[++c] = [ input[i] ];
            }
            i++;
        }
        i = 0;
        l = n.length;
        while (i < l) {
            n[i] = jQuery(n[i]);
            i++;
        }
        return n;
    }
});

jQuery(function($) {
    setNav();
    $('a[href^="#"]').click(function() {
        var target = $(this).attr("href");
        $("html, body").animate({
            scrollTop: $(target).offset().top
        }, 800);
        return false;
    });
    $(window).resize(function(event) {
        if (window.innerWidth < 769) {
            $("video").click(function(event) {});
        }
    });
    $(window).scroll(function() {
        setNav();
    });
    $(".nav-link").click(function() {
        if (window.innerWidth < 991) {
            $(".navbar-toggle").click();
        }
    });
    $("#my-modal").modal({
        show: "false"
    });
    $("#mGsend").submit(function(e) {
        e.preventDefault();
        $.post("send.php", $("#mGsend").serialize(), function(msg) {
            if (msg != "error") {
                $("#myModal").modal("show");
                $("#mGsend").find("input, textarea").val("");
            } else {
                $("#myModal_error").modal("show");
            }
        });
    });
    $("#language").change(function() {
        console.log(window.location.pathname);
        console.log($(this).val());
        window.location.href = "" + window.location.pathname + "?lang=" + $(this).val();
    });
});

function setNav() {
    $(".nav-link").removeClass("active");
    if ($(window).scrollTop() < $("#screen2").offset().top - $(window).height() / 2) {
        $(".construction a").addClass("active");
    } else if ($(window).scrollTop() < $("#screen3").offset().top - $(window).height() / 2) {
        $(".vid a").addClass("active");
    } else if ($(window).scrollTop() < $("#screen4").offset().top - $(window).height() / 2) {
        $(".functions a").addClass("active");
    } else if ($(window).scrollTop() < $("#screen5").offset().top - $(window).height() / 2) {
        $(".unique a").addClass("active");
    } else if ($(window).scrollTop() < $("#screen6").offset().top - $(window).height() / 2) {
        $(".people a").addClass("active");
    } else if ($(window).scrollTop() < $("#screen7").offset().top - $(window).height() / 2) {
        $(".newspaper a").addClass("active");
    } else {
        $(".interface a").addClass("active");
    }
}

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}

function setControl() {
    $(".play").click(function() {
        $(this).off();
        player.playVideo();
        setControl();
    });
    $(".pause").click(function() {
        $(this).off();
        player.pauseVideo();
        setControl();
    });
}

function onPlayerStateChange(event) {
    if (event.data == 1) {
        $(".play").addClass("pause");
        $(".play").removeClass("play");
        setControl();
    } else {
        $(".pause").addClass("play");
        $(".pause").removeClass("pause");
        setControl();
    }
}

var tag = document.createElement("script");

tag.src = "//www.youtube.com/player_api";

var firstScriptTag = document.getElementsByTagName("script")[0];

firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;

function onYouTubePlayerAPIReady() {
    console.log("here");
    player = new YT.Player("video", {
        events: {
            onReady: setControl,
            onStateChange: onPlayerStateChange
        }
    });
}